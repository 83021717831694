<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons"/>
      </div>
      <div class="align-right">
        <headerbuttonstack v-if="stage===1" :state="sideButtons" />
      </div>
    </div>
    <div v-if="stage === 1"
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Mitarbeiter Sync Einstellungen
    </div>
    <div v-if="stage === 2"
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Exporte Übersicht
    </div>

    <div v-if="loading">
      <i class="fas fa-circle-notch fa-spin"></i> Bitte warten<br/>
      Die Mitarbeiter Sync Einstellungen werden gerade geladen<br/>
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && !employeeSyncSetting">
      <strong>Ihr Lohnverrechnungstool wird aktuell nicht für den Mitarbeiter Sync unterstützt.</strong>
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && !hasAccessRights && employeeSyncSetting">
      <strong>Sie haben nicht die Berechtigung um die Mitarbeiter Sync Einstellungen für diese Firma anzusehen.</strong>
      <br/>
      Bitte wenden Sie sich an Ihren Store Administrator, er kann Ihnen die erforderlichen Rechte zuweisen.
      <br style="clear: both"/>
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && hasAccessRights && otherError && employeeSyncSetting">
      <strong>Bei der Datenabfrage ist ein Fehler aufgetreten.</strong>
      <br/>
      Bitte versuchen Sie die Seite neu zu laden.<br/>
      <br/>
      Sollte der Fehler danach immer noch auftreten wenden Sie sich bitte an den
      Support.<br/>
      <small class="text-muted">&lt; {{ otherErrorMessage }} &gt;</small>
      <br style="clear: both"/>
      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div v-if="!loading && hasAccessRights && !otherError && companyData == null && employeeSyncSetting">
      Die Companydaten wurden noch nicht geladen
      <br style="clear: both"/>

      <div
        style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 25px"
      ></div>
    </div>

    <div class="parentBox" v-if="!loading && hasAccessRights && !otherError && companyData != null && employeeSyncSetting && stage === 1">

      <!-- Einstellungen -->
      <div class="xColumn2">
        <div class="header">Einstellungen</div>

        <!-- change mode -->
        <div class="entryBox">
          <div class="entryHeader">{{ softwareIds[companyData.softwareId] }} Mitarbeiterdaten Änderungsmodus</div>
          <div v-if="saves || !editieren" class="entryData">
            <span v-if="true">{{ enumEmployeeChangedFromBMDModes[employeeSyncSettingsData.employeeChangeMode] }}</span>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <select v-model="employeeSyncSettingsData_input.employeeChangeMode" style="width: 98%">
              <option
                v-for="(entry, key) in enumEmployeeChangedFromBMDModes"
                :value="key"
                :key="key"
              >
                {{ entry }}
              </option>
            </select>
          </div>
        </div>

        <!-- internal id -->
        <div v-if="" class="entryBox">
          <div class="entryHeader">Unternehmen ML ID</div>
          <div class="entryData">
            {{ internalId }}
          </div>
        </div>

        <!-- company external id -->
        <div
          v-if="companyData.softwareId === 0 && employeeSyncSetting.externalId"
          class="entryBox"
        >
          <div class="entryHeader">Unternehmen BMD ID</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ bmdId }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputBmdId" style="width: 98%"/>
          </div>
        </div>
        <div
          v-if="companyData.softwareId === 1 && employeeSyncSetting.externalId"
          class="entryBox"
        >
          <div class="entryHeader">Unternehmen DPW ID</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ dpwId }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="inputDpwId" style="width: 98%"/>
          </div>
        </div>

        <!-- pdf payslipPassword -->
        <div v-if="employeeSyncSetting.payslipPassword" class="entryBox">
          <div class="entryHeader">Lohn-/Gehaltszettel PDF Passwort</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ getPayslipPasswordSettingByKey(employeeSyncSettingsData.payslipPassword) }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <select v-model="employeeSyncSettingsData_input.payslipPassword" style="width: 98%">
              <option v-for="item in payslipPasswordSettings" :value="item.value" :key="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>

        <!-- taxOfficeSerialNumber -->
        <div v-if="employeeSyncSetting.taxOfficeSerialNumber" class="entryBox">
          <div class="entryHeader">Finanzamt Lfd-Nr.</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ employeeSyncSettingsData.taxOfficeSerialNumber }}
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <input v-model="employeeSyncSettingsData_input.taxOfficeSerialNumber" style="width: 98%"/>
          </div>
        </div>

        <!-- default financial account id -->
        <div v-if="employeeSyncSetting.defaultFinancialAccountingGroupId" class="entryBox">
          <div class="entryHeader">Standard FIBU-Kreis</div>

          <div v-show="saves || !editieren" class="entryData">
            <span v-if="true">{{
              financialAccountingGroups.find(elem => elem.isDefault) !== undefined ?
                financialAccountingGroups.find(elem => elem.isDefault).name :
                  financialAccountingGroups.find(elem => elem.id === employeeSyncSettingsData.defaultFinancialAccountingGroupId) !== undefined ?
                    financialAccountingGroups.find(elem => elem.id === employeeSyncSettingsData.defaultFinancialAccountingGroupId).name :
                    ""
              }}</span>
          </div>
          <div v-show="!saves && editieren" class="entryData">
            <span v-if="true">{{
              financialAccountingGroups.find(elem => elem.isDefault) !== undefined ?
                financialAccountingGroups.find(elem => elem.isDefault).name :
                  financialAccountingGroups.find(elem => elem.id === employeeSyncSettingsData.defaultFinancialAccountingGroupId) !== undefined ?
                    financialAccountingGroups.find(elem => elem.id === employeeSyncSettingsData.defaultFinancialAccountingGroupId).name :
                    ""
              }}</span>
              <span>
                &nbsp;<i v-tooltip="'Bitte setzen Sie den Standard FIBU Kreis in den Einstellungen -> FIBU Kreise'" class="fas fa-info-circle"></i>
              </span>
          </div>
        </div>

        <!-- exportLastNameBlockLetters -->
        <div v-if="employeeSyncSetting.exportLastNameBlockLetters" class="entryBox">
          <div class="entryHeader">Nachname in BLOCKSCHRIFT exportieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.exportLastNameBlockLetters }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <i class="fas fa-check-circle checkbox" :key="'cb-ewco'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.exportLastNameBlockLetters }"
               v-on:click="employeeSyncSettingsData_input.exportLastNameBlockLetters = !employeeSyncSettingsData_input.exportLastNameBlockLetters"></i>
          </div>
        </div>

        <!-- ignoreExternalEmployees -->
        <div v-if="employeeSyncSetting.ignoreExternalEmployees" class="entryBox">
          <div class="entryHeader">Keine externen Mitarbeiter exportieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.ignoreExternalEmployees }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <i class="fas fa-check-circle checkbox" :key="'cb-ewco'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.ignoreExternalEmployees }"
               v-on:click="employeeSyncSettingsData_input.ignoreExternalEmployees = !employeeSyncSettingsData_input.ignoreExternalEmployees"></i>
          </div>
        </div>

        <!-- ignoreHiddenEmployees -->
        <div v-if="employeeSyncSetting.ignoreHiddenEmployees" class="entryBox">
          <div class="entryHeader">Keine versteckten Mitarbeiter exportieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.ignoreHiddenEmployees }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <i class="fas fa-check-circle checkbox" :key="'cb-ewco'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.ignoreHiddenEmployees }"
               v-on:click="employeeSyncSettingsData_input.ignoreHiddenEmployees = !employeeSyncSettingsData_input.ignoreHiddenEmployees"></i>
          </div>
        </div>

        <!-- ignoreInactiveEmployees -->
        <div v-if="employeeSyncSetting.ignoreInactiveEmployees" class="entryBox">
          <div class="entryHeader">Keine inaktiven Mitarbeiter exportieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.ignoreInactiveEmployees }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <i class="fas fa-check-circle checkbox" :key="'cb-ewco'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.ignoreInactiveEmployees }"
               v-on:click="employeeSyncSettingsData_input.ignoreInactiveEmployees = !employeeSyncSettingsData_input.ignoreInactiveEmployees"></i>
          </div>
        </div>

        <!-- exportLatestContractOnly -->
        <div v-if="employeeSyncSetting.exportLatestContractOnly" class="entryBox">
          <div class="entryHeader">Nur aktuellsten Vertrag exportieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.exportLatestContractOnly }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <i class="fas fa-check-circle checkbox" :key="'cb-eac'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.exportLatestContractOnly }"
               v-on:click="employeeSyncSettingsData_input.exportLatestContractOnly = !employeeSyncSettingsData_input.exportLatestContractOnly"></i>
          </div>
        </div>

        <!-- enableExportViaTransferService -->
        <div v-if="employeeSyncSetting.enableExportViaTransferService" class="entryBox">
          <div class="entryHeader">Direkten Export ins LV Tool aktivieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.enableExportViaTransferService }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData">
            <i class="fas fa-check-circle checkbox" :key="'cb-eac'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.enableExportViaTransferService }"
               v-on:click="employeeSyncSettingsData_input.enableExportViaTransferService = !employeeSyncSettingsData_input.enableExportViaTransferService"></i>
          </div>
        </div>

        <!-- enablePeriodicExport -->
        <div v-if="employeeSyncSetting.enablePeriodicExport" class="entryBox">
          <div class="entryHeader">Periodischen Export aktivieren</div>
          <div v-if="saves || !editieren" class="entryData">
            <i class="fas fa-check-circle checkbox disabled"
               v-bind:class="{ false: !employeeSyncSettingsData.enablePeriodicExport }"></i>
          </div>
          <div v-if="!saves && editieren" class="entryData" style="display:flex; justify-content:space-between">
            <i class="fas fa-check-circle checkbox" :key="'cb-eac'"
               v-bind:class="{ false: !employeeSyncSettingsData_input.enablePeriodicExport }"
               v-on:click="employeeSyncSettingsData_input.enablePeriodicExport = !employeeSyncSettingsData_input.enablePeriodicExport"></i>
            <i v-if="employeeSyncSettingsData_input.enablePeriodicExport" class="fas fa-plus" v-tooltip="'Zeit hinzufügen'"
               v-on:click="periodicExportTimes_input.push({type: 0, dayOfMonth: 0, dayOfWeek: 0, hours: 0, minutes: 0})"></i>
          </div>
        </div>
        <!-- periodic export times -->
        <div v-if="(saves || !editieren) && employeeSyncSettingsData.enablePeriodicExport" v-for="time in periodicExportTimes" class="entryBox">
          <div class="entryHeader" style="text-align: right; padding-right: 10px">{{ periodicExportTimeTypes.find(e => e.value === time.type).label }}</div>
          <div class="entryData">{{ getPeriodicTimeLabel(time) }}</div>
        </div>
        <div v-if="!saves && editieren && employeeSyncSettingsData_input.enablePeriodicExport"
             v-for="(time, index) in periodicExportTimes_input" :key="index" class="entryBox">
          <div class="entryHeader">
            <select v-model="time.type" style="width: 98%">
              <option v-for="item in periodicExportTimeTypes" :value="item.value" :key="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
          <div class="entryData">
            <select v-if="time.type === 1" v-model="time.dayOfWeek" style="width: 150px">
              <option v-for="item in getPeriodicTimeTypeInputs(time.type)" :value="item.value" :key="item.value">
                {{ item.label }}
              </option>
            </select>
            <select v-if="time.type === 2" v-model="time.dayOfMonth" style="width: 150px">
              <option v-for="item in getPeriodicTimeTypeInputs(time.type)" :value="item.value" :key="item.value">
                {{ item.label }}
              </option>
            </select>
            &nbsp;
            <input v-model="time.hours" type="number" style="width: 27px" />
            :
            <input v-model="time.minutes" type="number" style="width: 27px" />
            &nbsp;
            <i v-tooltip="'Zeit löschen'" class="fas fa-trash" v-on:click="deletePeriodicExportTime(index)" />
          </div>
        </div>

      </div>

      <!-- contract WeekModelNr -->
      <div v-if="employeeSyncSetting.contractWeekModelNr" class="xColumn2">
        <div class="header" style="display: flex; justify-content: space-between">
          <div style="">
            Wochenmodelle
          </div>
          <div style="width: fit-content; float: right">
            <i class="fas fa-check-circle checkbox" :key="'cb-hdc'"
               v-bind:class="{ false: !hideDeactivatedContracts, disabled: !saves && editieren }"
               v-on:click="hideDeactivatedContracts = !hideDeactivatedContracts"></i>
            <small class="text-secondary">&nbsp;Deaktivierte Verträge ausblenden&nbsp;</small>
            <br>
            <i class="fas fa-check-circle checkbox" :key="'cb-csc'"
               v-bind:class="{ false: !combineSimilarContracts, disabled: !saves && editieren }"
               v-on:click="combineSimilarContracts = !combineSimilarContracts"></i>
            <small class="text-secondary">&nbsp;Ähnliche Verträge zusammenfassen&nbsp;</small>
          </div>
        </div>

        <div class="entryBox triple">
          <div v-if="combineSimilarContracts" class="entryHeader first">MD Kollektivvertrag</div>
          <div v-if="!combineSimilarContracts" class="entryHeader first">MD Kollektivvertrag (Store ID)</div>
          <div class="entryHeader">Wochenstunden und Tage</div>
          <div class="entryHeader">{{ softwareIds[companyData.softwareId] }} Wochenmodell</div>
        </div>
        <div v-if="combineSimilarContracts" v-for="(collectiveContractArray, ccId) in similarAllowedContracts">
          <template v-for="(weekWorkDaysArray, weekWorkDays) in collectiveContractArray">
            <template v-if="weekWorkDays !== 'ccId'" v-for="(weekTimeArray, weekTime) in weekWorkDaysArray">
              <div class="entryBox triple" style="padding: 2px 7px">
                <div class="entryHeaderLight first">{{ collectiveContractMap[ccId].name }}</div>
                <div class="entryData">{{
                    Math.round(((weekTime / 60.0) + Number.EPSILON) * 100) / 100 + " Stunden in " + weekWorkDays + " Tagen"
                  }}
                </div>
                <div v-if="saves || !editieren" class="entryData">{{ similarContractWeekModelNrArray[ccId][weekWorkDays][weekTime] }}</div>
                <div v-if="!saves && editieren" class="entryData"><input v-model="similarContractWeekModelNrArray_input[ccId][weekWorkDays][weekTime]"/></div>
                <br style="clear: both"/>
              </div>
            </template>
          </template>
        </div>
        <div v-if="!combineSimilarContracts" v-for="storeAllowedContractArray in filteredAllowedContracts">
          <div class="entryBox triple" style="padding: 2px 7px" v-for="ac in storeAllowedContractArray">
            <div class="entryHeaderLight first">{{ ac.collectiveContractName + " (" + ac.storeId + ")" }}</div>
            <div class="entryData">
              <i v-tooltip="'Details'"
                 v-on:click="selectedAllowedContract = ac; openAcDetailsModal()" class="fas fa-magnifying-glass"></i>
              {{ Math.round(((ac.weekTimeRegular / 60.0) + Number.EPSILON) * 100) / 100 + " Stunden in " + ac.weekWorkDaysRegular + " Tagen" }}
            </div>
            <div v-if="saves || !editieren" class="entryData">{{ ac.externalId }}</div>
            <div v-if="!saves && editieren" class="entryData"><input v-model="ac.externalId_input"/></div>
            <br style="clear: both"/>
          </div>
        </div>
      </div>

      <!-- Kollektivverträge -->
      <div v-if="employeeSyncSetting.collectiveContracts" class="xColumn2">
        <div class="header">Kollektivverträge</div>

        <div class="entryBox triple">
          <div class="entryHeader first">MD Kollektivvertrag</div>
          <div class="entryHeader">{{ softwareIds[companyData.softwareId] }} ID Angestellte</div>
          <div class="entryHeader">{{ softwareIds[companyData.softwareId] }} ID Arbeiter</div>
        </div>
        <div class="entryBox triple" style="padding: 2px 7px" v-for="cc in filteredCCs">
          <div class="entryHeaderLight first">{{ cc.name }}</div>
          <div v-if="saves || !editieren" class="entryData">
            {{ cc.externalIdEmployee ? cc.externalIdEmployee : "&nbsp;" }}
          </div>
          <div v-if="saves || !editieren" class="entryData">{{ cc.externalIdWorker }}</div>
          <div v-if="!saves && editieren" class="entryData"><input v-model="cc.externalIdEmployee_input"/></div>
          <div v-if="!saves && editieren" class="entryData"><input v-model="cc.externalIdWorker_input"/></div>
          <br style="clear: both"/>
        </div>
      </div>

      <!-- Tarifgruppen -->
      <div v-if="employeeSyncSetting.wageGroups" class="xColumn2">
        <div class="header">Tarifgruppen</div>

        <div class="entryBox triple">
          <div class="entryHeader first">&nbsp;</div>
          <div class="entryHeader">Angstellte</div>
          <div class="entryHeader">Arbeiter</div>
        </div>
        <div v-if="saves || !editieren" class="entryBox triple">
          <div class="entryHeader first">Vollversicherter Dienstnehmer</div>
          <div class="entryData">{{ wageGroup.fullyInsuredEmployee ? wageGroup.fullyInsuredEmployee : "&nbsp;" }}</div>
          <div class="entryData">{{ wageGroup.fullyInsuredWorker ? wageGroup.fullyInsuredWorker : "&nbsp;" }}</div>
        </div>
        <div v-if="saves || !editieren" class="entryBox triple">
          <div class="entryHeader first">Geringfügiger Dienstnehmer</div>
          <div class="entryData">
            {{ wageGroup.marginallyEmployedEmployee ? wageGroup.marginallyEmployedEmployee : "&nbsp;" }}
          </div>
          <div class="entryData">
            {{ wageGroup.marginallyEmployedWorker ? wageGroup.marginallyEmployedWorker : "&nbsp;" }}
          </div>
        </div>
        <div v-if="saves || !editieren" class="entryBox triple">
          <div class="entryHeader first">Lehrling</div>
          <div class="entryData">{{ wageGroup.apprenticeEmployee ? wageGroup.apprenticeEmployee : "&nbsp;" }}</div>
          <div class="entryData">{{ wageGroup.apprenticeWorker ? wageGroup.apprenticeWorker : "&nbsp;" }}</div>
        </div>
        <!--        <div v-if="saves || !editieren" class="entryBox triple">-->
        <!--          <div class="entryHeader first">Freier Dienstnehmer</div>-->
        <!--          <div class="entryData">{{ wageGroup.independentContractorEmployee ? wageGroup.independentContractorEmployee : "&nbsp;"}}</div>-->
        <!--          <div class="entryData">{{ wageGroup.independentContractorWorker ? wageGroup.independentContractorWorker : "&nbsp;"}}</div>-->
        <!--        </div>-->
        <!--        <div v-if="saves || !editieren" class="entryBox triple">-->
        <!--          <div class="entryHeader first">Geringfügiger Freier Dienstnehmer</div>-->
        <!--          <div class="entryData">{{ wageGroup.marginallyEmployedIndependentContractorEmployee ? wageGroup.marginallyEmployedIndependentContractorEmployee : "&nbsp;"}}</div>-->
        <!--          <div class="entryData">{{ wageGroup.marginallyEmployedIndependentContractorWorker ? wageGroup.marginallyEmployedIndependentContractorWorker : "&nbsp;"}}</div>-->
        <!--        </div>-->
        <div v-if="!saves && editieren" class="entryBox triple">
          <div class="entryHeader first">Vollversicherter Dienstnehmer</div>
          <div class="entryData"><input v-model="wageGroup_input.fullyInsuredEmployee"/></div>
          <div class="entryData"><input v-model="wageGroup_input.fullyInsuredWorker"/></div>
        </div>
        <div v-if="!saves && editieren" class="entryBox triple">
          <div class="entryHeader first">Geringfügiger Dienstnehmer</div>
          <div class="entryData"><input v-model="wageGroup_input.marginallyEmployedEmployee"/></div>
          <div class="entryData"><input v-model="wageGroup_input.marginallyEmployedWorker"/></div>
        </div>
        <div v-if="!saves && editieren" class="entryBox triple">
          <div class="entryHeader first">Lehrling</div>
          <div class="entryData"><input v-model="wageGroup_input.apprenticeEmployee"/></div>
          <div class="entryData"><input v-model="wageGroup_input.apprenticeWorker"/></div>
        </div>
        <!--        <div v-if="!saves && editieren" class="entryBox triple">-->
        <!--          <div class="entryHeader first">Freier Dienstnehmer</div>-->
        <!--          <div class="entryData"><input v-model="wageGroup_input.independentContractorEmployee" /></div>-->
        <!--          <div class="entryData"><input v-model="wageGroup_input.independentContractorWorker" /></div>-->
        <!--        </div>-->
        <!--        <div v-if="!saves && editieren" class="entryBox triple">-->
        <!--          <div class="entryHeader first">Geringfügiger Freier Dienstnehmer</div>-->
        <!--          <div class="entryData"><input v-model="wageGroup_input.marginallyEmployedIndependentContractorEmployee" /></div>-->
        <!--          <div class="entryData"><input v-model="wageGroup_input.marginallyEmployedIndependentContractorWorker" /></div>-->
        <!--        </div>-->
      </div>

      <!-- Standorte -->
      <div v-if="employeeSyncSetting.externalStoreId
              || employeeSyncSetting.costCenter
              || employeeSyncSetting.municipalitySerialNumber
              || employeeSyncSetting.allocationGroup
              || employeeSyncSetting.bankAccountOriginator"
           class="xColumn2">
        <div class="header">
          Standorte
          <small class="text-secondary"><em>(Kostenstellen)</em></small>
        </div>

        <div class="entryBox" style="padding-bottom: 0px" v-for="store in storesDataFiltered"
             v-bind:key="store.id + 'store'">
          <div>
            <div class="entryHeader">
              Standort Name
            </div>
            <div class="entryData">
              {{ store.name }}
            </div>
          </div>
          <br style="clear: both"/>

          <div>
            <div class="entryHeader">
              Zusatztext
            </div>
            <div class="entryData">
              {{ store.description }}
            </div>
          </div>
          <br style="clear: both"/>

          <div>
            <div class="entryHeader">
              Standort MD ID
            </div>
            <div class="entryData">
              {{ store.id }}
            </div>
          </div>
          <br style="clear: both"/>

          <div v-if="employeeSyncSetting.externalStoreId && companyData.softwareId == 0">
            <div class="entryHeader">
              Standort BMD ID
            </div>
            <div v-if="saves || !editieren" class="entryData">
              {{ store.bmdId }}
            </div>
            <div v-if="!saves && editieren" class="entryData">
              <input v-model="store.inputBmdId"/>
            </div>
          </div>
          <div v-if="employeeSyncSetting.externalStoreId && companyData.softwareId == 1">
            <div class="entryHeader">
              Standort DPW ID
            </div>
            <div v-if="saves || !editieren" class="entryData">
              {{ store.dpwId }}
            </div>
            <div v-if="!saves && editieren" class="entryData">
              <input v-model="store.inputDpwId"/>
            </div>
          </div>
          <br v-if="employeeSyncSetting.externalStoreId && (companyData.softwareId == 0 || companyData.softwareId == 1)" style="clear: both"/>

          <div v-if="employeeSyncSetting.costCenter">
            <div class="entryHeader">
              Standort Kostenstelle ID
            </div>
            <div v-if="saves || !editieren" class="entryData">
              {{ store.costCenter }}
            </div>
            <div v-if="!saves && editieren" class="entryData">
              <input v-model="store.inputCostCenter"/>
            </div>
          </div>
          <br v-if="employeeSyncSetting.costCenter" style="clear: both"/>

          <div v-if="employeeSyncSetting.municipalitySerialNumber">
            <div class="entryHeader">
              Standort Gemeinde Lfd-Nr.
            </div>
            <div v-if="saves || !editieren" class="entryData">
              {{ store.municipalitySerialNumber }}
            </div>
            <div v-if="!saves && editieren" class="entryData">
              <input v-model="store.inputMunicipalitySerialNumber"/>
            </div>
          </div>
          <br v-if="employeeSyncSetting.municipalitySerialNumber" style="clear: both"/>

          <div v-if="employeeSyncSetting.allocationGroup">
            <div class="entryHeader">
              Standort Standard Abrechnungskreis
            </div>
            <div v-if="saves || !editieren" class="entryData">
              {{ store.allocationGroup }}
            </div>
            <div v-if="!saves && editieren" class="entryData">
              <input v-model="store.inputAllocationGroup"/>
            </div>
          </div>
          <br v-if="employeeSyncSetting.allocationGroup" style="clear: both"/>

          <div v-if="employeeSyncSetting.bankAccountOriginator">
            <div class="entryHeader">
              Standort Bank Auftraggeber <i class="fa fa-circle-info" v-tooltip="'Bitte geben Sie die Bankverbindung als BLZ/Kontonummer oder als BIC/IBAN an!'" />
            </div>
            <div v-if="saves || !editieren" class="entryData">
              {{ store.bankAccountOriginator }}
            </div>
            <div v-if="!saves && editieren" class="entryData">
              <input v-model="store.inputBankAccountOriginator"/>
            </div>
          </div>
          <br v-if="employeeSyncSetting.bankAccountOriginator" style="clear: both"/>

          <hr style="clear: both; margin-bottom: 0px"/>
        </div>
      </div>

      <!-- Fields -->
      <div class="xColumn2">
        <div class="header" style="width: 100%">
          Mitarbeiter Sync Felder

          <i class="fas fa-redo" style="float: right; margin-top:2px;" @click="openResetDropdown();"/>
          <small class="text-secondary" style="float: right">&nbsp;Auf Standardwerte zurücksetzen&nbsp;</small>
        </div>
        <div style="display: flex; padding: 5px;">
          <datatablesimple style="flex-basis: calc(100% - 25px)" :state="fieldsState"/>
        </div>
      </div>

      <br style="clear: both"/>
      <div style="width: 100%; clear: both; border-bottom: 1px solid #ccc; margin-top: 5px"></div>
    </div>

    <div class="parentBox" v-if="!loading && hasAccessRights && !otherError && companyData != null && employeeSyncSetting && stage === 2">

      <table class="table">
        <tr>
          <th>Gestartet von</th>
          <th>Export gestartet</th>
          <th>Export gesendet</th>
          <th>Status</th>
          <th>Export herunterladen</th>
        </tr>
        <tr v-for="exportJob in exportJobsSorted" v-bind:key="exportJob.id">
          <td>{{ authusers[exportJob.authuserId].name }}</td>
          <td>{{ exportJob.jobStarted }}</td>
          <td>{{ exportJob.jobEnded }}</td>
          <td>
            <i v-if="exportJob.status === 'FAILED'" style="color: red" class="fas fa-xmark" v-on:click="selectedExportJob = exportJob; openExportJobStatusModal()"></i>
            <i v-if="exportJob.status === 'DONE'" style="color: green" class="fas fa-check" v-on:click=""></i>
            <i v-if="exportJob.status === 'IGNORE-WARNING'" style="color: yellow" class="fas fa-check" v-on:click="selectedExportJob = exportJob; openExportJobStatusModal()"></i>
            <i v-if="exportJob.status === 'WARNING'" style="color: yellow" class="fas fa-check" v-on:click="selectedExportJob = exportJob; openExportJobStatusModal()"></i>
            <i v-if="exportJob.status === 'WARNING'" style="color: yellow" class="fas fa-triangle-exclamation" v-on:click="selectedExportJob = exportJob; openExportJobStatusModal()"></i>
          </td>
          <td>
            <i v-if="exportJob.exportCSV" class="fas fa-file-download" v-on:click="downloadCSVFromExportJob(exportJob.exportCSV)"></i>
          </td>
        </tr>
      </table>

    </div>

    <b-modal size="lg" ref="modal-ac-details" @close="closeAcDetailsModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Kollektivvertrag Details</div>
        </div>
      </template>
      <b-container fluid>
        <div class="full">
          <table style="width: 100%">
            <tr>
              <th scope="col">ID</th>
              <td>{{ selectedAllowedContract.id }}</td>
            </tr>
            <tr>
              <th scope="col">Store ID</th>
              <td>{{ selectedAllowedContract.storeId }}</td>
            </tr>
            <tr>
              <th scope="col">Store</th>
              <td>{{ Object.keys(selectedAllowedContract).length > 0 ? JSON.parse(JSON.stringify(storesDataFiltered.find(store => store.id === selectedAllowedContract.storeId))).name : ""}}</td>
            </tr>
            <tr>
              <th scope="col">KV Name</th>
              <td>{{ Object.keys(selectedAllowedContract).length > 0 ? collectiveContractMap[selectedAllowedContract.collectiveContractId].name : ""}}</td>
            </tr>
            <tr>
              <th scope="col">Wochentage</th>
              <td>{{ selectedAllowedContract.weekWorkDaysRegular }}</td>
            </tr>
            <tr>
              <th scope="col">Wochenstunden</th>
              <td>{{ Math.round(((selectedAllowedContract.weekTimeRegular / 60.0) + Number.EPSILON) * 100) / 100 }}</td>
            </tr>
            <tr>
              <th scope="col">Zusätzliche Monatsstunden</th>
              <td>{{ Math.round(((selectedAllowedContract.additionalMonthWorkTime / 60.0) + Number.EPSILON) * 100) / 100 }}</td>
            </tr>

          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div class="button right" @click="closeAcDetailsModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

    <b-modal size="lg" ref="modal-exportjob-status" @close="closeExportJobStatusModal()" centered>
      <template slot="modal-header">
        <div class="error-modal">
          <div class="headerlabel">Export Details</div>
        </div>
      </template>
      <b-container fluid>
        <div v-if="selectedExportJob && selectedExportJob.status === 'FAILED'" class="full">
          Der Export konnte nicht generiert werden. Bitte wenden Sie sich an das Mein Dienstplan Support Team.
        </div>
        <div v-if="selectedExportJob && selectedExportJob.status === 'IGNORE-WARNING'" class="full">
          Der Export wurde erfolgreich generiert und versendet.<br>Folgende MitarbeiterInnen wurden aufgrund der Export Einstellungen übersprungen und nicht exportiert:<br>
          <table v-if="getInactiveEmployeeWarnings(selectedExportJob).length > 0">
            <th><td><br>Übersprungene inaktive Mitarbeiter</td></th>
            <tr v-for="emp in getInactiveEmployeeWarnings(selectedExportJob)"><td>{{ getEmployeeName(emp.employeeId) }}</td></tr>
          </table>
          <table v-if="getExternalEmployeeWarnings(selectedExportJob).length > 0">
            <th><td><br>Übersprungene externe Mitarbeiter</td></th>
            <tr v-for="emp in getExternalEmployeeWarnings(selectedExportJob)"><td>{{ getEmployeeName(emp.employeeId) }}</td></tr>
          </table>
          <table v-if="getHiddenEmployeeWarnings(selectedExportJob).length > 0">
            <th><td><br>Übersprungene versteckte Mitarbeiter
              <i v-if="showHiddenWarnings" class="fas fa-chevron-circle-up" style="padding-left: 5px" v-on:click="showHiddenWarnings = !showHiddenWarnings"/>
              <i v-if="!showHiddenWarnings" class="fas fa-chevron-circle-down" style="padding-left: 5px" v-on:click="showHiddenWarnings = !showHiddenWarnings"/>
            </td></th>
            <tr v-if="showHiddenWarnings" v-for="emp in getHiddenEmployeeWarnings(selectedExportJob)"><td>{{ getEmployeeName(emp.employeeId) }}</td></tr>
          </table>
        </div>
        <div v-if="selectedExportJob && selectedExportJob.status === 'WARNING'" class="full">
          Der Export wurde erfolgreich generiert und versendet.<br>Folgende Warnungen traten während dem Export auf:<br>
          <table v-for="table in getOtherWarningsTable(selectedExportJob)">
            <th><td><br>{{ warningTranslations[table.name] }}</td></th>
            <tr v-for="empId in table.emps"><td>{{ getEmployeeName(empId) }}</td></tr>
          </table>
          <table v-if="getInactiveEmployeeWarnings(selectedExportJob).length > 0">
            <th><td><br>Übersprungene inaktive Mitarbeiter</td></th>
            <tr v-for="emp in getInactiveEmployeeWarnings(selectedExportJob)"><td>{{ getEmployeeName(emp.employeeId) }}</td></tr>
          </table>
          <table v-if="getExternalEmployeeWarnings(selectedExportJob).length > 0">
            <th><td><br>Übersprungene externe Mitarbeiter</td></th>
            <tr v-for="emp in getExternalEmployeeWarnings(selectedExportJob)"><td>{{ getEmployeeName(emp.employeeId) }}</td></tr>
          </table>
          <table v-if="getHiddenEmployeeWarnings(selectedExportJob).length > 0">
            <th><td><br>Übersprungene versteckte Mitarbeiter
              <i v-if="showHiddenWarnings" class="fas fa-chevron-circle-up" style="padding-left: 5px" v-on:click="showHiddenWarnings = !showHiddenWarnings"/>
              <i v-if="!showHiddenWarnings" class="fas fa-chevron-circle-down" style="padding-left: 5px" v-on:click="showHiddenWarnings = !showHiddenWarnings"/>
            </td></th>
            <tr v-if="showHiddenWarnings" v-for="emp in getHiddenEmployeeWarnings(selectedExportJob)"><td>{{ getEmployeeName(emp.employeeId) }}</td></tr>
          </table>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div v-if="selectedExportJob && selectedExportJob.status === 'FAILED'" class="button right"
             @click="openInNewTab('https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/11'); closeExportJobStatusModal()">
          <div class="label">Support kontaktieren</div>
        </div>
        <div class="button right" @click="closeExportJobStatusModal()">
          <div class="label">Schließen</div>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VueEventBus from "@/vue-event-bus";

export default {
  name: "companysettings",
  data() {
    return {
      stage: 1,

      softwareIds: {
        0: "BMD",
        1: "DPW",
        2: "DATEV über SOTEC",
      },
      payslipPasswordSettings: [
        {
          value: 0,
          label: "4-stellige SVNR",
        },
        {
          value: 1,
          label: "10-stellige SVNR",
        }
      ],
      periodicExportTimeTypes: [
        {
          value: 0,
          label: "Täglich",
        },
        {
          value: 1,
          label: "Wöchentlich",
        },
        {
          value: 2,
          label: "Monatlich",
        }
      ],
      bmdImportScriptFunctions: [
        {
          value: "MCS_FRMLOHNMITARBEITERCSVIMPORT_CREATE",
          label: "Lohnmitarbeiter importieren"
        },
        {
          value: "MCS_FRMABRECHNUNGCSVIMPORT_CREATE",
          label: "Abrechnungen importieren"
        },
        {
          value: "MCS_FRMABWESENHEITCSVIMPORT_CREATE",
          label: "Nichtleistungszeiten importieren"
        },
      ],
      bmdImportScriptFunctionFolder: {
        MCS_FRMLOHNMITARBEITERCSVIMPORT_CREATE: "employeeData",
        MCS_FRMABRECHNUNGCSVIMPORT_CREATE: "monthlyFinancialStatementData",
        MCS_FRMABWESENHEITCSVIMPORT_CREATE: "absenceData",
      },
      enumEmployeeChangedFromBMDModes: {
        0: "Mitarbeiteränderungen bestätigen",
        1: "Mitarbeiteränderungen sofort übernehmen",
      },

      loading: true,
      longLoading: false,
      loadingDownload: false,
      loadingExport: false,

      editieren: false,

      companyData: null,
      storesData: null,

      internalId: "",
      companyName: "",
      companyDescription: "",
      bmdId: "",
      dpwId: "",

      inputBmdId: "",
      inputDpwId: "",

      collectiveContractMappingArray: [],
      collectiveContractMap: {},
      collectiveContractArray: [],

      allowedContractArray: [],
      selectedAllowedContract: {},

      contractWeekModelNrMappingArray: [],
      contractWeekModelNrMap: {},
      contractWeekModelNrArray: [],
      similarContractWeekModelNrArray: [],
      similarContractWeekModelNrArray_input: [],
      combineSimilarContracts: true,
      hideDeactivatedContracts: true,

      financialAccountingGroups: [],

      employeeSyncSetting: null,
      employeeSyncSettingsData: {
        id: -1,
        payslipPassword: null,
        taxOfficeSerialNumber: "",
        exportLastNameBlockLetters: true,
        ignoreExternalEmployees: true,
        ignoreHiddenEmployees: true,
        ignoreInactiveEmployees: true,
        exportLatestContractOnly: true,
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
        employeeChangeMode: 0,
        defaultFinancialAccountingGroupId: -1,
      },
      employeeSyncSettingsData_input: {
        id: -1,
        payslipPassword: null,
        taxOfficeSerialNumber: "",
        exportLastNameBlockLetters: true,
        ignoreExternalEmployees: true,
        ignoreHiddenEmployees: true,
        ignoreInactiveEmployees: true,
        exportLatestContractOnly: true,
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
        employeeChangeMode: 0,
        defaultFinancialAccountingGroupId: -1,
      },

      employeeSyncFieldSettings: [],

      wageGroup: {
        id: -1,
        fullyInsuredEmployee: "",
        fullyInsuredWorker: "",
        marginallyEmployedEmployee: "",
        marginallyEmployedWorker: "",
        apprenticeEmployee: "",
        apprenticeWorker: "",
        independentContractorEmployee: "",
        independentContractorWorker: "",
        marginallyEmployedIndependentContractorEmployee: "",
        marginallyEmployedIndependentContractorWorker: "",
      },
      wageGroup_input: {
        id: -1,
        fullyInsuredEmployee: "",
        fullyInsuredWorker: "",
        marginallyEmployedEmployee: "",
        marginallyEmployedWorker: "",
        apprenticeEmployee: "",
        apprenticeWorker: "",
        independentContractorEmployee: "",
        independentContractorWorker: "",
        marginallyEmployedIndependentContractorEmployee: "",
        marginallyEmployedIndependentContractorWorker: "",
      },

      periodicExportTimes: [],
      periodicExportTimes_input: [],

      exportJobs: [],
      warningTranslations: {
        "Removed IBAN: IBAN is not valid.": "IBAN wurde entfernt: IBAN ist nicht korrekt",
        "Removed SVNR: SVNR is not valid.": "SVNR wurde entfernt: SVNR ist nicht korrekt",
      },
      ignorableWarnings: [
          "Employee skipped: External Employee",
          "Employee skipped: Inactive Employee"
      ],
      hardIgnorableWarnings: [
          "Employee skipped: Hidden Employee"
      ],
      showHiddenWarnings: false,
      authusers: [],
      selectedExportJob: null,

      saves: false,
      hasAccessRights: true,
      otherError: false,
      otherErrorMessage: "",
    };
  },
  created: function () {
    //console.log("CREATED");
    VueEventBus.$on("JobEmployeeExportEvent:jobCreated", this.jobCreated);
    VueEventBus.$on("JobEmployeeExportEvent:jobStarted", this.jobStarted);
    VueEventBus.$on("JobEmployeeExportEvent:jobEnded", this.jobEnded);
    VueEventBus.$on("JobEmployeeExportEvent:jobFailed", this.jobFailed);
  },
  beforeDestroy: function () {
    VueEventBus.$off("JobEmployeeExportEvent:jobCreated", null);
    VueEventBus.$off("JobEmployeeExportEvent:jobStarted", null);
    VueEventBus.$off("JobEmployeeExportEvent:jobEnded", null);
    VueEventBus.$off("JobEmployeeExportEvent:jobFailed", null);
  },
  mounted: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {

      this.refresh();
    }
  },
  computed: {
    ...mapGetters([
      "companyId",
      "isSuperUser",
      "externalServicesMap",
      "companySettings",
      "selectedStores",
      "employeeArray",
    ]),
    dateDisplay: function () {
      return this.$helpers.getMomentTZ().format("YYYY-MM-DD");
    },
    softwareId() {
      if (this.companyData != null && this.companyData.softwareId != null) {
        return this.companyData.softwareId;
      } else {
        return -1;
      }
    },
    headerButtons() {
      let view = this;

      let state = {
        buttons: [
          {
            type: "block border-r",
            icon: "fa-edit",
            show: view.stage === 1 && !view.saves && !view.editieren,
            disabled: view.loadingDownload || view.loadingExport,
            click: () => {
              view.editieren = !view.editieren;
            },
            tooltip: "Bearbeiten",
            title: "Bearbeiten",
          },
          {
            type: view.employeeSyncSettingsData.enableExportViaTransferService ? "block" : "block border-r",
            icon: "fa-file-download",
            show: view.stage === 1 && view.employeeSyncSetting && !view.loadingDownload && !view.saves && !view.editieren,
            disabled: view.loadingExport,
            click: () => {
              view.downloadCSV();
            },
            tooltip: "Download Export",
            title: "Download Export",
          },
          {
            show: view.stage === 1 && view.loadingDownload,
            type: view.employeeSyncSettingsData.enableExportViaTransferService ? "block" : "block border-r",
            icon: "fa-spinner fa-spin",
            click: () => {
            },
            tooltip: "Download wird generiert",
          },
          {
            type: "block",
            icon: "fa-file-export",
            show: view.stage === 1 && view.employeeSyncSetting && view.employeeSyncSettingsData.enableExportViaTransferService && !view.loadingExport && !view.saves && !view.editieren,
            disabled: view.loadingDownload,
            click: () => {
              view.exportCSV();
            },
            tooltip: "Export zur Lohnverrechnung",
            title: "Export zur Lohnverrechnung",
          },
          {
            show: view.stage === 1 && view.loadingExport,
            type: "block border-r",
            icon: "fa-spinner fa-spin",
            click: () => {
            },
            tooltip: "Export wird generiert",
          },
          {
            type: "block border-r",
            icon: "fa-magnifying-glass",
            show: view.stage === 1 && view.employeeSyncSetting && view.employeeSyncSettingsData.enableExportViaTransferService && !view.saves && !view.editieren,
            disabled: view.loadingDownload || view.loadingExport,
            click: () => {
              view.stage = 2;
            },
            tooltip: "Exporte ansehen",
            title: "Exporte ansehen",
          },
          {
            type: "block",
            icon: "fa-chevron-left",
            show: view.stage === 2,
            click: () => {
              view.stage = 1;
            },
            tooltip: "Zurück zu den Einstellungen",
            title: "Zurück zu den Einstellungen",
          },
          {
            type: "block red",
            icon: "fa-ban",
            show: view.stage === 1 && !view.saves && view.editieren,
            click: () => {
              view.resetInputs();
              view.editieren = !view.editieren;
            },
            tooltip: "Abbrechen",
          },
          {
            type: "block green",
            icon: "fa-save",
            show: view.stage === 1 && !view.saves && view.editieren,
            click: () => {
              view.save();
            },
            tooltip: "Änderung speichern",
          },
          {
            show: view.stage === 1 && view.saves,
            type: "block",
            icon: "fa-spinner fa-spin",
            click: () => {
            },
            tooltip: "wird gespeichert",
          },
        ],
        helperLinks: [
          {
            title: "Mitarbeiter Sync",
            helperText:
              "Nutzen Sie die Einstellungen auf dieser Seite, um Ihre Importe und Exporte perfekt für Ihr Lohnverrechnungs-Tool anzupassen. Für eine detailierte Beschreibung, klicken Sie auf 'Mehr erfahren'.",
            externalUrl:
              "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/2599256065",
            externalLabel: "Mehr erfahren",
            cancelLabel: "Danke",
            icon: "fa-rotate"
          },
          {
            title: "Support erhalten",
            helperText:
              "Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf 'Support kontaktieren', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf 'Senden'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.",
            externalUrl:
              "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/11",
            externalLabel: "Support kontaktieren",
            cancelLabel: "Schließen",
          }
        ]
      };
      return state;
    },
    sideButtons() {
      let view = this;

      let state = {
        buttons: [
          {
            type: "block",
            icon: "fa-bars",
            tooltip: "Zusätzliche Downloads",
            click: ($event) => {
              view.OpenMenuDetails($event, this.downloadsMenuState);
            },
          }
        ],
      };
      return state;
    },
    downloadsMenuState(){
      let view = this;
      let state = {
        componentref: 'burgermenustate',
        groups: [],
        items: []
      };
      let group1 = {
        label: "Zusätzliche Downloads",
        items: [
          {
            closeOnClick: true,
            icon: "fa-folder-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "Standard BMD Schnittstelle \"Dienstnehmer generieren mittels Importdatei\"",
            click: () => { view.downloadImportBmdInterface(); },
          },
          {
            closeOnClick: true,
            icon: "fa-file-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "BMD Makro \"SVNR Check\"",
            click: () => { view.openSvnrCheckMakroPopup() },
          },
          {
            closeOnClick: true,
            icon: "fa-folder-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "Standard BMD Schnittstelle \"Dienstnehmer exportieren\"",
            click: () => { view.downloadExportBmdInterface() },
          },
          {
            closeOnClick: true,
            icon: "fa-file-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "BMD Makro \"Gültig ab\"",
            click: () => { view.openValidFromMakroPopup() },
          },
          {
            closeOnClick: true,
            icon: "fa-file-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "BMD Formel \"Lohn lt. KV\"",
            click: () => { view.openWageFormulaPopup() },
          },
          // {
          //   closeOnClick: true,
          //   icon: "fa-file-arrow-down",
          //   show: this.softwareId === 0,
          //   disabled: view.loadingDownload || view.loadingExport,
          //   text: "BMD Zugangsdaten für Import via Kommandozeile generieren",
          //   click: () => { view.openBmdLoginDataPopup() },
          // },
          // {
          //   closeOnClick: true,
          //   icon: "fa-file-arrow-down",
          //   show: this.softwareId === 0,
          //   disabled: view.loadingDownload || view.loadingExport,
          //   text: "BMD Import Script für Import via Kommandozeile generieren",
          //   click: () => { view.openBmdImportScriptPopup() },
          // },
          {
            closeOnClick: true,
            icon: "fa-folder-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "BMD Import Ordner Struktur herunterladen",
            click: () => { view.openBmdEditelFolderStructurePopup() },
          },
          {
            closeOnClick: true,
            icon: "fa-folder-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "BMD Dienstnehmer Import Makro und Stapel herunterladen",
            click: () => { view.openEmployeeImportStapelPopup() },
          },
          {
            closeOnClick: true,
            icon: "fa-folder-arrow-down",
            show: this.softwareId === 0,
            disabled: view.loadingDownload || view.loadingExport,
            text: "BMD Dienstnehmer Export Stapel herunterladen",
            click: () => { view.openEmployeeExportStapelPopup() },
          },
          {
            closeOnClick: true,
            icon: "fa-file-times",
            show: this.softwareId !== 0,
            disabled: true,
            text: "Für Ihr Lohnverrechnungstool stehen aktuell keine Downloads zur Verfügung.",
          },
        ]
      }

      state.groups.push(group1);

      return state;
    },
    storesDataFiltered() {
      let filtered = [];
      this.storesData.forEach((s) => {
        /*if (
          this.selectedStores.filter((elem) => {
            return s.id == elem.id;
          }).length > 0
        )*/
        filtered.push(s);
      });
      return filtered;
    },
    fieldsState() {
      let view = this;
      let state = this.$helpers.GetDataTableStateFromKeyLabelObject(
        {
          listStructure: "Export Aufbau",
          columns: "Individuelle Feldbezeichnungen",
        },
        Object.values(this.employeeSyncFieldSettings)
      );

      state.headerMap.listStructure.componentStateBuilders = [
        (data, entry) => {
          if (data.disabled === undefined) {
            return {};
          } else {
            return {
              componentType: "buttonc",
              label: data.defaultName,
              type: "occupy-container",
              tooltip: data.disabled === true ? "Dieses Feld ist mit den aktuellen LV Software-Einstellungen nicht verfügbar." : "Auswählen / Abwählen",
              class: `${data.showField === 1 && !data.disabled ? "selected" : ""}`,
              customEvents: {
                click: () => {
                  if (!data.disabled) {
                    data.showField === 1 ? data.showField = 0 : data.showField = 1;
                    view.saveEmployeeSyncFieldSettings();
                    view.$forceUpdate();
                  }
                },
              },
              style: "height: calc(100% - 5px) !important; width: calc(100% - 8px) !important;",
              styleLabel: {"text-align": "left", width: "100%"},
            };
          }
        },
      ];
      state.headerMap.columns.componentStateBuilders = [
        (data, entry) => {
          return {
            componentType: "inputc",
            inputProp: data.fieldName,
            placeholder: data.fieldName,
            style: "text-align: left; box-shadow: inset 0 0 0 1px var(--contrast-3); height: calc(100% - 5px); width: calc(100% - 8px); top: 0px",
            customEvents: {
              Update: (val) => {
                data.fieldName = val;
                view.saveEmployeeSyncFieldSettings();
                view.$forceUpdate();
              },
            },
          };
        },
      ];
      Object.values(state.headerMap).forEach((h) => (h.stylesHeader = {"justify-content": "start"}));
      state.compact = true;
      state.draggable = true;
      state.dragOptions = {
        handle: ".fa-up-down",
        onStart: function (/**Event*/event) {
          // remove strange "glow" from "transform: translateZ(0px)"
          event.item.style.transform = null;
        },
        onUpdate(/**Event*/event) {
          view.updateFieldOrder(event.oldIndex + 1, event.newIndex + 1);
          view.saveEmployeeSyncFieldSettings();
        }
      };
      return state;
    },
    filteredCCs() {
      if (this.collectiveContractMappingArray.length === 0) {
        return [];
      } else if (this.filteredAllowedContracts.length === 0) {
        return this.collectiveContractMappingArray;
      } else {
        let ccs = new Set();
        this.filteredAllowedContracts.forEach(storeArray => {
          storeArray.forEach(ac => {
            ccs.add(ac.collectiveContractId);
          });
        });
        return this.collectiveContractMappingArray.filter(cc => {
          return ccs.has(cc.mdId);
        });
      }
    },
    similarAllowedContracts() {
      let similarAllowedContracts = {};
      this.filteredAllowedContracts.forEach(storeContracts => {
        storeContracts.forEach(ac => {
          if (!(ac.collectiveContractId in similarAllowedContracts)) {
            similarAllowedContracts[ac.collectiveContractId] = {};
          }
          if (!(ac.weekWorkDaysRegular in similarAllowedContracts[ac.collectiveContractId])) {
            similarAllowedContracts[ac.collectiveContractId][ac.weekWorkDaysRegular] = {};
          }
          if (!(ac.weekTimeRegular in similarAllowedContracts[ac.collectiveContractId][ac.weekWorkDaysRegular])) {
            similarAllowedContracts[ac.collectiveContractId][ac.weekWorkDaysRegular][ac.weekTimeRegular] = {};
          }
          if (ac.externalId) {
            similarAllowedContracts[ac.collectiveContractId][ac.weekWorkDaysRegular][ac.weekTimeRegular][ac.externalId] = {};
          }
        })
      });

      // copy computed values to separate arrays to handle user inputs
      this.similarContractWeekModelNrArray = JSON.parse(JSON.stringify(similarAllowedContracts));
      for (const collectiveContractArray in this.similarContractWeekModelNrArray) {
        for (const weekWorkDaysArray in this.similarContractWeekModelNrArray[collectiveContractArray]) {
          for (const weekTimeArray in this.similarContractWeekModelNrArray[collectiveContractArray][weekWorkDaysArray]) {
            this.similarContractWeekModelNrArray[collectiveContractArray][weekWorkDaysArray][weekTimeArray] =
              this.printAssoArray(this.similarContractWeekModelNrArray[collectiveContractArray][weekWorkDaysArray][weekTimeArray]);
          }
        }
      }
      this.similarContractWeekModelNrArray_input = JSON.parse(JSON.stringify(this.similarContractWeekModelNrArray));

      return similarAllowedContracts;
    },
    filteredAllowedContracts() {
      let filteredAllowedContracts = [];
      this.allowedContractArray.forEach(storeArray => {
        let filteredStoreArray = [];
        storeArray.forEach(ac => {
          if (!this.hideDeactivatedContracts || ac.active) {
            filteredStoreArray.push(ac);
          }
        });
        filteredAllowedContracts.push(filteredStoreArray);
      });
      return filteredAllowedContracts;
    },
    exportJobsSorted() {
      return this.exportJobs.sort((a,b) => {
        if (a.created_at > b.created_at) {
          return -1;
        } else if (b.created_at > a.created_at) {
          return 1;
        } else {
          return 0;
        }
      });
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
    selectedStores: function (val, oldVal) {
      console.log("Selected stores changed in CompanySettings.vue");
      console.log(oldVal);
      console.log(val);
    },
    periodicExportTimes_input: {
      handler (val, oldVal) {
        val.forEach(time => {
          let hours = parseInt(time.hours);
          if (hours > 23) {
            time.hours = 23;
          } else if (hours < 10) {
            time.hours = "0" + hours;
          } else {
            time.hours = hours;
          }

          let minutes = parseInt(time.minutes);
          if (minutes > 59) {
            time.minutes = 59;
          } else if (minutes < 10) {
            time.minutes = "0" + minutes;
          } else {
            time.minutes = minutes;
          }
        });
      },
      deep: true,
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;
      view.$helpers.GetEmployeeArray();
      view.prepareCollectiveContracts();
      view.prepareWageGroups();
      view.preparePeriodicExportTimes();
      view.loadPeriodicExportTimes();
      view.loadFinancialAccountingGroups();
      view.loadExportJobs();
      view.$helpers
        .GetCompanySettings(force)
        .then((response) => {
          view.updateCompanySettings(response);
          view.prepareEmployeeSyncSettings();
          view.getEmployeeSyncFields();
          return view.$helpers.GetStoresSettings(force)
        })
        .then((response) => {
          view.storesData = response.data;

          view.allowedContractArray = [];
          let promises = [];
          for (let i = 0; i < view.storesData.length; i++) {
            view.storesData[i].inputBmdId = view.storesData[i].bmdId;
            view.storesData[i].inputDpwId = view.storesData[i].dpwId;
            view.storesData[i].inputCostCenter = view.storesData[i].costCenter;
            view.storesData[i].inputStoreNumber = view.storesData[i].storeNumber;
            view.storesData[i].inputMunicipalitySerialNumber = view.storesData[i].municipalitySerialNumber;
            view.storesData[i].inputAllocationGroup = view.storesData[i].allocationGroup;
            view.storesData[i].inputBankAccountOriginator = view.storesData[i].bankAccountOriginator;

            promises.push(this.loadAllowedContracts(view.storesData[i].id));
          }
          Promise.all(promises).then((response) => {
            view.prepareContractWeekModelNrs()
              .then((response) => {
                view.resetInputs();
                view.loading = false;
              });
          });
        })
        .catch((error) => {
          console.log("REFRESH ERROR: " + error);
          view.$helpers.error("Fehler", "Fehler beim Laden der Daten");

          if (error && error.response && error.response.status == 403) {
            view.hasAccessRights = false;
          }
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            view.otherErrorMessage = error.response.data.message;
          }
          view.otherError = true;
          //view.$helpers.error('Fehler', 'Fehler beim Laden der Daten');
          setTimeout(function () {
            view.loading = false;
          }, 200);
        });
    },
    resetInputs() {
      let view = this;

      view.inputBmdId = view.bmdId;
      view.inputDpwId = view.dpwId;

      view.employeeSyncSettingsData_input = JSON.parse(JSON.stringify(view.employeeSyncSettingsData));
      view.wageGroup_input = JSON.parse(JSON.stringify(view.wageGroup));

      view.collectiveContractMappingArray.forEach((obj) => {
        obj.externalIdEmployee_input = obj.externalIdEmployee;
        obj.externalIdWorker_input = obj.externalIdWorker;
      });

      view.storesDataFiltered.forEach((store) => {
        store.inputBmdId = store.bmdId;
        store.inputDpwId = store.dpwId;
      });

      Object.values(view.allowedContractArray).forEach(acperstore => {
        Object.values(acperstore).forEach(ac => {
          ac.externalId_input = ac.externalId;
        })
      });
      view.similarContractWeekModelNrArray_input = view.similarContractWeekModelNrArray;

    },

    // menu
    OpenMenuDetails($event, state){
      let view = this;

      this.$helpers.OpenPopup({
        type: 'burgermenu',
        componentState: state,
        listStyle: true,
        noScroll: true,
        pointerDirection: 0,
        elRelative: $event.currentTarget,
        pointerOffset: -15,
      });
    },

    // collective contracts
    prepareCollectiveContracts() {
      let view = this;
      view.collectiveContractMappingArray = [];
      view.collectiveContractMap = {};
      view.collectiveContractArray = [];
      view.getCollectiveContractMap(true)
        .then((resp) => {
          view.getCollectiveContractMapping()
            .then((mapping) => {
              Object.values(view.collectiveContractMap).forEach(obj => {
                let result = mapping.find(res => {
                  return res.mdId === obj.id
                });
                let nameChanged = obj.country === 2 ? obj.name + " (DE)" : obj.name;
                obj.name = nameChanged;
                let push = {
                  name: nameChanged,
                  mdId: obj.id,
                  externalIdEmployee_input: null,
                  externalIdWorker_input: null
                };
                if (result !== undefined) {
                  push.externalIdEmployee = result.externalIdEmployee;
                  push.externalIdWorker = result.externalIdWorker;
                  push.externalIdEmployee_input = result.externalIdEmployee;
                  push.externalIdWorker_input = result.externalIdWorker;
                  push.id = result.id;
                }
                view.collectiveContractMappingArray.push(push);
              });
            });
        });
    },
    getCollectiveContractMap(force = false, asArray = false) {
      if (this.collectiveContractMap && !force) {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(this.collectiveContractMap, asArray));
        });
      } else {
        return this.$helpers.GetCall('/api/sec/proxy/md/collectiveContracts').then((response) => {
          let map = {};
          response.data.forEach(collectiveContract => {
            map[collectiveContract.id] = collectiveContract;
          });
          this.collectiveContractMap = map;
          this.collectiveContractArray = this.$helpers.returnObj(map, true);
          return new Promise((resolve, reject) => {
            resolve(this.$helpers.returnObj(map, asArray));
          });
        }).catch((e) => {
          return new Promise((resolve, reject) => {
            reject(e);
          });
        });
      }
    },
    getCollectiveContractMapping() {
      return this.$helpers.GetCall('/api/sec/employeesync/collectiveContracts').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    saveCollectiveContracts() {
      let view = this;
      let objects = [];
      Object.values(view.collectiveContractMappingArray).forEach(cc => {
        let object = JSON.parse(JSON.stringify(cc));
        object.externalIdEmployee = cc.externalIdEmployee_input;
        object.externalIdWorker = cc.externalIdWorker_input;
        if ((cc.hasOwnProperty("externalIdEmployee") && cc.externalIdEmployee !== cc.externalIdEmployee_input)
          || (cc.hasOwnProperty("externalIdWorker") && cc.externalIdWorker !== cc.externalIdWorker_input)) {
          // cc mapping exists and has updated values
          objects.push(object);
        } else if ((!cc.hasOwnProperty("externalIdEmployee") && cc.externalIdEmployee_input !== null && cc.externalIdEmployee_input !== "")
          || (!cc.hasOwnProperty("externalIdWorker") && cc.externalIdWorker_input !== null && cc.externalIdWorker_input !== "")) {
          // cc mapping doesn't exist, but value is not empty
          objects.push(object);
        }
        // cc mapping exists, but no updated values
        // cc mapping doesn't exist and value was not entered
        // skip entry
      });
      let payload = {
        data: objects
      };

      let url = '/api/sec/employeesync/collectiveContracts/bulk';
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

      return this.axios({
        method: "put",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url,
        data: payload
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Speichern der Kollektivverträge."
        );
      });
    },

    loadAllowedContracts(storeId, force = false, asArray = false) {
      let view = this;
      let params = {
        storeId: storeId
      };
      return this.$helpers.GetCall('/api/sec/proxy/md/allowedContracts', params)
        .then((response) => {
          Object.values(response.data).forEach(allowedContract => {
            allowedContract.collectiveContractName = view.collectiveContractMap[allowedContract.collectiveContractId].name;
            allowedContract.externalId = "";
            allowedContract.externalId_input = "";
          });
          view.allowedContractArray[storeId] = response.data;
        }).catch((e) => {
        });
    },

    // contract week model nrs
    prepareContractWeekModelNrs() {
      let view = this;
      return view.getContractWeekModelNrMapping()
        .then((mapping) => {
          Object.values(view.allowedContractArray).forEach(storeObj => {
            Object.values(storeObj).forEach(obj => {
              let result = mapping.find(res => {
                return res.allowedContractId === obj.id
              });
              if (result !== undefined) {
                obj.mappingId = result.id;
                obj.externalId = result.externalId;
                obj.externalId_input = result.externalId;
              }
            });
          });
        });
    },
    getContractWeekModelNrMapping() {
      return this.$helpers.GetCall('/api/sec/employeesync/weekModelNr').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    saveContractWeekModelNrs() {
      let view = this;
      let objects = [];

      if (!view.combineSimilarContracts) {
        Object.values(view.allowedContractArray).forEach(acperstore => {
          Object.values(acperstore).forEach(ac => {
            let object = {
              companyId: this.$store.state.companyId,
              allowedContractId: ac.id,
              externalId: ac.externalId_input
            }
            if (ac.mappingId !== undefined) {
              object.id = ac.mappingId;
            }
            if (ac.hasOwnProperty("externalId") && ac.externalId !== ac.externalId_input) {
              // ac mapping exists and has updated values
              objects.push(object);
            } else if (!ac.hasOwnProperty("externalId") && ac.externalId_input !== null && ac.externalId_input !== "") {
              // ac mapping doesn't exist, but value is not empty
              objects.push(object);
            }
            // ac mapping exists, but no updated values
            // ac mapping doesn't exist and value was not entered
            // skip entry
          })
        });
      } else {
        for (const collectiveContractId in view.similarContractWeekModelNrArray) {
          for (const weekWorkDays in view.similarContractWeekModelNrArray[collectiveContractId]) {
            for (const weekTime in view.similarContractWeekModelNrArray[collectiveContractId][weekWorkDays]) {
              let oldVal = view.similarContractWeekModelNrArray[collectiveContractId][weekWorkDays][weekTime];
              let newVal = view.similarContractWeekModelNrArray_input[collectiveContractId][weekWorkDays][weekTime];
              if (oldVal !== newVal && parseInt(newVal).toString() === newVal) {
                Object.values(view.allowedContractArray).forEach(acperstore => {
                  Object.values(acperstore).forEach(ac => {
                    if (ac.collectiveContractId.toString() === collectiveContractId
                      && ac.weekWorkDaysRegular.toString() === weekWorkDays
                      && ac.weekTimeRegular.toString() === weekTime) {

                      ac.externalId_input = newVal;
                      let object = {
                        companyId: this.$store.state.companyId,
                        allowedContractId: ac.id,
                        externalId: ac.externalId_input
                      }
                      if (ac.mappingId !== undefined) {
                        object.id = ac.mappingId;
                      }
                      objects.push(object);
                    }
                  })
                });
              }
            }
          }
        }
      }

      console.log(objects);
      let payload = {
        data: objects
      };

      let url = '/api/sec/employeesync/weekModelNr/bulk';
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

      return this.axios({
        method: "put",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url,
        data: payload
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Speichern der Wochenmodelle."
        );
      });
    },

    // employee sync settings
    prepareEmployeeSyncSettings() {
      let view = this;
      view.employeeSyncSetting = null;
      view.employeeSyncSettingsData = {
        id: -1,
        payslipPassword: null,
        taxOfficeSerialNumber: "",
        exportLastNameBlockLetters: true,
        ignoreExternalEmployees: true,
        ignoreHiddenEmployees: true,
        ignoreInactiveEmployees: true,
        exportLatestContractOnly: true,
        enableExportViaTransferService: false,
        enablePeriodicExport: false,
        employeeChangeMode: 0,
        defaultFinancialAccountingGroupId: -1,
      };
      view.employeeSyncSettingsData_input = JSON.parse(JSON.stringify(view.employeeSyncSettingsData));
      view.getEmployeeSyncSettings()
        .then((settings) => {
          view.employeeSyncSetting = settings.find(res => {
            return res.softwareId === view.companyData.softwareId
          });
          if (view.employeeSyncSetting === undefined) {
            view.employeeSyncSetting = null;
          }
          view.getEmployeeSyncSettingsData()
            .then((data) => {
              view.employeeSyncSettingsData = JSON.parse(JSON.stringify(data));
              view.employeeSyncSettingsData_input = JSON.parse(JSON.stringify(data));
            }).catch((e) => {

          });
        });
    },
    getEmployeeSyncSettings() {
      return this.$helpers.GetCall('/api/sec/employeesync/settings').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    getEmployeeSyncSettingsData() {
      return this.$helpers.GetCall('/api/sec/employeesync/data').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    saveEmployeeSyncSettingsData() {
      let view = this;

      let method = 'post';
      let url = '/api/sec/employeesync/data';
      if (view.employeeSyncSettingsData.id !== -1) {
        url = url + '/' + view.employeeSyncSettingsData.id;
        method = 'put';
      }
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url,
        data: view.employeeSyncSettingsData_input
      }).catch(function (error) {
        view.employeeSyncSettingsData_input = JSON.parse(JSON.stringify(view.employeeSyncSettingsData));
        view.$helpers.error(
          "Fehler",
          "Fehler beim Speichern der allgemeinen Einstellungen."
        );
      });
    },

    // periodic export times
    getPeriodicTimeTypeInputs(type) {
      switch(type) {
        case 1: return [
          { label: "Sonntags", value: 0 },
          { label: "Montags", value: 1 },
          { label: "Dienstags", value: 2 },
          { label: "Mittwochs", value: 3 },
          { label: "Donnerstags", value: 4 },
          { label: "Freitags", value: 5 },
          { label: "Samstags", value: 6 }
        ];
        case 2:
          let items = [];
          for (let i = 0; i <= 30; i++) {
            items.push({ label: (i+1) + ". Tag des Monats", value: i});
          }
          items.push({ label: "letzter Tag des Monats", value: 31 });
          return items;
        default: return [];
      }
    },
    getPeriodicTimeLabel(time) {
      let view = this;
      let values = view.getPeriodicTimeTypeInputs(time.type);
      let label = "";

      let hours = parseInt(time.hours);
      if (hours > 23) {
        time.hours = 23;
      } else if (hours < 10) {
        time.hours = "0" + hours;
      } else {
        time.hours = hours;
      }

      let minutes = parseInt(time.minutes);
      if (minutes > 59) {
        time.minutes = 59;
      } else if (minutes < 10) {
        time.minutes = "0" + minutes;
      } else {
        time.minutes = minutes;
      }

      switch (time.type) {
        case 0: return time.hours + ":" + time.minutes;
        case 1: label = values[time.dayOfWeek].label; break;
        case 2: label = values[time.dayOfMonth].label; break;
      }
      return label + " um " + time.hours + ":" + time.minutes;
    },
    preparePeriodicExportTimes() {
      let view = this;

      view.periodicExportTimes = [];
      view.periodicExportTimes_input = [];

      this.loadPeriodicExportTimes()
      .then((response) => {
        view.periodicExportTimes = JSON.parse(JSON.stringify(response));
        view.periodicExportTimes_input = JSON.parse(JSON.stringify(response));
      });
    },
    loadPeriodicExportTimes() {
      return this.$helpers.GetCall('/api/sec/employeesync/periodicExportSettings').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    savePeriodicExportTimes() {
      let view = this;

      let payload = {
        data: view.periodicExportTimes_input
      };

      let url = '/api/sec/employeesync/periodicExportSettings/bulk';
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

      return this.axios({
        method: "put",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url,
        data: payload
      }).catch(function (error) {
        view.$helpers.error(
            "Fehler",
            "Fehler beim Speichern der periodischen Export Zeiten."
        );
      });
    },
    deletePeriodicExportTime(index) {
      let view = this;
      let elementAtIndex = view.periodicExportTimes_input[index];

      // remove remote if it has id
      if (elementAtIndex.id !== undefined) {
        console.log("element had id");
        this.$helpers.DeleteCall('/api/sec/employeesync/periodicExportSettings/' + elementAtIndex.id, elementAtIndex)
        .then((response) => {
          let idx = view.periodicExportTimes.indexOf(view.periodicExportTimes.find(time => time.id === elementAtIndex.id));
          // remove locally
          view.periodicExportTimes.splice(idx, 1);
          view.periodicExportTimes_input.splice(index, 1);
        }).catch((e) => {
          view.$helpers.error(
              "Fehler",
              "Fehler beim Löschen der Zeit."
          );
        });
      } else {
        console.log("element did not have id");
        // remove from input array only
        view.periodicExportTimes_input.splice(index, 1);
      }
    },

    // employee sync fields & field settings
    getEmployeeSyncFields() {
      let view = this;

      let method = 'get';
      let baseUrl = '/api/sec/employeesync/fields';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((fieldsResponse) => {
        this.getEmployeeSyncFieldSettings()
          .then((settingsResponse) => {
            if (settingsResponse !== null && settingsResponse.data !== null && settingsResponse.data.length > 0) {
              let localSettings = [];
              Object.values(settingsResponse.data).forEach((element) => {
                localSettings[element.fieldId] = element;
              });
              this.getEmployeeSyncFieldSettingDefaults()
                .then((response) => {
                  let newField = false;
                  Object.values(response.data).forEach((element) => {
                    if (localSettings[element.fieldId] !== undefined) {
                      localSettings[element.fieldId].defaultName = element.fieldInternalName;
                    } else {
                      newField = true;
                      localSettings[element.fieldId] = element;
                      localSettings[element.fieldId].defaultName = element.fieldInternalName;
                    }
                  });
                  view.loadSettingsResponse(fieldsResponse, localSettings);
                  if (newField) {
                    view.saveEmployeeSyncFieldSettings();
                  }
                });
            } else {
              this.getEmployeeSyncFieldSettingDefaults()
                .then((response) => {
                  let localSettings = [];
                  Object.values(response.data).forEach((element) => {
                    localSettings[element.fieldId] = element;
                    localSettings[element.fieldId].defaultName = element.fieldInternalName;
                  });
                  view.loadSettingsResponse(fieldsResponse, localSettings);
                });
            }
          });
      }).catch(function (error) {
        console.log(error);
        view.$helpers.error(
          "Fehler",
          "Fehler beim Laden der Mitarbeiter Sync Felder."
        );
      });
    },
    getEmployeeSyncFieldSettings() {
      let view = this;

      let method = 'get';
      let baseUrl = '/api/sec/employeesync/fields/settings';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).catch(function (error) {
        console.log(error);
        view.$helpers.error(
          "Fehler",
          "Fehler beim Laden der Mitarbeiter Sync Feld Einstellungen."
        );
      });
    },
    getEmployeeSyncFieldSettingDefaults() {
      let view = this;

      let method = 'get';
      let baseUrl = '/api/sec/employeesync/fields/settings/default';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseUrl);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Laden der Mitarbeiter Sync Feld Default Einstellungen."
        );
      });
    },
    resetEmployeeSyncFields() {
      let view = this;

      let method = 'post';
      let baseUrl = '/api/sec/employeesync/fields/settings/reset';
      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseUrl);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).catch(function (error) {
        view.$helpers.error(
          "Fehler",
          "Fehler beim Laden der Mitarbeiter Sync Feld Default Einstellungen."
        );
      });
    },
    loadSettingsResponse(response, localSettings) {
      Object.values(response.data).forEach((field) => {
        if (field.fieldName === "payslipPassword" && !this.employeeSyncSetting.payslipPassword) {
          localSettings[field.id].disabled = true;
        } else if (field.fieldName === "taxOfficeSerialNumber" && !this.employeeSyncSetting.taxOfficeSerialNumber) {
          localSettings[field.id].disabled = true;
        } else if (field.fieldName === "municipalitySerialNumber" && !this.employeeSyncSetting.municipalitySerialNumber) {
          localSettings[field.id].disabled = true;
        } else if (field.fieldName === "collectiveContractExternalId" && !this.employeeSyncSetting.collectiveContracts) {
          localSettings[field.id].disabled = true;
        } else if ((field.fieldName === "wageGroup" || field.fieldName === "wageGroupSwap") && !this.employeeSyncSetting.wageGroups) {
          localSettings[field.id].disabled = true;
        } else {
          localSettings[field.id].disabled = false;
        }
      });
      localSettings.sort((a, b) => (a.fieldOrder > b.fieldOrder) ? 1 : ((b.fieldOrder > a.fieldOrder) ? -1 : 0))
      this.employeeSyncFieldSettings = localSettings;
    },
    updateFieldOrder(from, to) {
      let view = this;

      Object.values(view.employeeSyncFieldSettings).forEach((element) => {
        if (element.fieldOrder === from) {
          //console.log(element.fieldOrder + " to " + to);
          element.fieldOrder = to;
        } else {
          if (from < to && element.fieldOrder > from && element.fieldOrder <= to) {
            //console.log(element.fieldOrder + " to " + (element.fieldOrder - 1));
            element.fieldOrder -= 1;
          } else if (from > to && element.fieldOrder < from && element.fieldOrder >= to) {
            //console.log(element.fieldOrder + " to " + (element.fieldOrder + 1));
            element.fieldOrder += 1;
          }
        }
      });
    },
    saveEmployeeSyncFieldSettings() {
      let view = this;

      if (!view.employeeSyncFieldSettingsDuplicates()) {
        let method = 'post';
        let url = '/api/sec/employeesync/fields/settings/all';
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

        let payload = {
          'data': view.employeeSyncFieldSettings
        }

        return this.axios({
          method: method,
          headers: {
            'AUTHORIZATION': "Bearer " + this.$store.state.jwt
          },
          url: url,
          data: payload
        }).catch(function (error) {
          view.$helpers.error(
            "Fehler",
            "Fehler beim Speichern der Mitarbeiter Sync Felder."
          );
        });
      } else {
        view.$helpers.error(
          "Duplikats Fehler",
          "Es gibt Duplikate in den Individuellen Feldbezeichnungen. Bitte bereinigen Sie diese!"
        );
      }
    },
    employeeSyncFieldSettingsDuplicates() {
      let view = this;
      var valueArr = view.employeeSyncFieldSettings.map(function (item) {
        return item.fieldName
      });
      return valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx
      });
    },

    // wage groups
    prepareWageGroups() {
      let view = this;
      view.wageGroup = {
        id: -1,
        fullyInsuredEmployee: "",
        fullyInsuredWorker: "",
        marginallyEmployedEmployee: "",
        marginallyEmployedWorker: "",
        apprenticeEmployee: "",
        apprenticeWorker: "",
        independentContractorEmployee: "",
        independentContractorWorker: "",
        marginallyEmployedIndependentContractorEmployee: "",
        marginallyEmployedIndependentContractorWorker: "",
      };
      view.wageGroup_input = JSON.parse(JSON.stringify(view.wageGroup));
      view.getWageGroups()
        .then((data) => {
          view.wageGroup = data;
          view.wageGroup_input = data;
        }).catch((e) => {

      });
    },
    getWageGroups() {
      return this.$helpers.GetCall('/api/sec/employeesync/wageGroup').then((response) => {
        return new Promise((resolve, reject) => {
          resolve(this.$helpers.returnObj(response.data, false));
        });
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject(e);
        });
      });
    },
    saveWageGroups() {
      let view = this;

      let method = 'post';
      let url = '/api/sec/employeesync/wageGroup';
      if (view.wageGroup.id !== -1) {
        url = url + '/' + view.wageGroup.id;
        method = 'put';
      }
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, url);

      return this.axios({
        method: method,
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url,
        data: view.wageGroup_input
      }).catch(function (error) {
        view.wageGroup_input = view.wageGroup;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Speichern der Tarifgruppen."
        );
      });
    },

    // bmd downloads
    // import
    downloadImportBmdInterface() {
      let view = this;

      let resourceName = "dienstnehmer_generieren.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    openSvnrCheckMakroPopup() {
      var view = this;

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        componentState: {
          description:
              'Dieses Makro prüft beim Import von Dienstnehmern in BMD, ob die SVNR bei Mitarbeitern mit gleicher Mitarbeiter-Nummer übereinstimmt.' +
              '<br>Falls dies nicht der Fall ist, wird der Mitarbeiter beim Import übersprungen.' +
              '<br>Damit wird verhindert, dass Mitarbeiter, die versehentlich eine falsche MA-Nr bekommen haben, keine falschen Mitarbeiter in BMD überschreiben.' +
              '<br><br>Um dieses Makro in BMD nutzen zu können, öffnen Sie zunächst das Fenster "Dienstnehmer generieren mittels Importdatei" und wählen Sie die gewünschte Schnittstelle aus.' +
              '<br>Wählen Sie nun in der Menüleiste des Fensters "Funktionen" -> "Makro vor Schreiben" aus.' +
              '<br>Falls sich der geöffnete Editor noch nicht im Experten Modus befindet, klicken Sie unter "Makro" auf die Schaltfläche "Auf Experten-Editor schalten".' +
              '<br>Anschließend können Sie den Text aus der heruntergeladenen Text Datei in den Editor kopieren und speichern.' +
              '<br>',
          entries: [],
          positiveButtonLabel: "BMD Makro \"SVNR Check\" herunterladen",
          selectCallback: function (result, event) {
            view.downloadSvnrCheckMakro();
          },
          errorCheck: {
          },
        },
        header: 'BMD Makro \"SVNR Check\" herunterladen',
        expanded: true,
        centered: true,
      });
    },
    downloadSvnrCheckMakro() {
      let view = this;

      let resourceName = "makro_dn_import_svnr_check.txt";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },

    // export
    downloadExportBmdInterface() {
      let view = this;

      let resourceName = "dienstnehmer_exportieren.zip";
      view.loadResourceFile("bmd", resourceName)
        .then((resp) => {
          let content = view.$helpers.convertToUInt8(atob(resp.data.content));
          view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
        });
    },
    openValidFromMakroPopup() {
      var view = this;

      let entries = [
        {
          label: "Makro Nummer",
          key: "macroNumber",
          hidden: false,
          type: "text",
          value: ""
        },
        {
          label: "Formel Text",
          key: "formulaText",
          hidden: false,
          type: "text",
          readonly: true,
          copyButton: true,
          copyButtonTooltip: "Formeltext in die Zwischenablage kopieren",
          value: "",
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let macroNumber = datafill.entries.find((i) => i.key === "macroNumber").value;
            entryBeforeUpdate.value = "FUNCTION[\"EXECUTEMACRO\";" + macroNumber + "; FUNCTION[\"CONCAT\";FUNCTION[\"CONCAT\";\"pFirmennr=\"; FIELD[\"MCA_PSJ_FIRMENNR\"] ] ; FUNCTION[\"CONCAT\";FUNCTION[\"CONCAT\";\";pMaid=\"; FIELD[\"MCA_PSJ_MITARBEITERID\"] ] ; FUNCTION[\"CONCAT\";\";pDvnr=\"; FIELD[\"MCA_DVH_DIENSTVLFDNR\"] ] ] ] ] §§T1S1E270L0/T3S25E40L1/T4S42E269L1/T1S42E268L2/T4S60E120L3/T1S60E119L4/T3S78E90L5/T4S92E118L5/T2S92E117L6/T4S122E267L3/T1S122E266L4/T4S140E202L5/T1S140E201L6/T3S158E167L7/T4S169E200L7/T2S169E199L8/T4S204E265L5/T1S204E264L6/T3S222E231L7/T4S233E263L7/T2S233E262L8/";
            return entryBeforeUpdate;
          },
        },
      ]

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        remainAfterSelect: true,
        componentState: {
          description:
              'Dieses Makro gibt beim Mitarbeiter Export das aktuellste "Gültig ab" Datum der Historischen Werte des Mitarbeiters an.' +
              '<br>Dieses Feld wird in ML beim Import benötigt, um die Mitarbeiter Verträge korrekt anzulegen.' +
              '<br><br>Um dieses Makro in BMD nutzen zu können, öffnen Sie zunächst das Fenster "Makros".' +
              '<br>Wählen Sie nun in der Menüleiste des Fensters "Extras" -> "Importieren" aus. Wählen Sie nun die heruntergeladene XML Datei aus.' +
              '<br>Nach einer etwaigen kurzen Ladezeit in BMD sollten Sie nun oben in der Liste das neue Makro sehen. Öffnen Sie das Makro und kopieren Sie die Makro Nummber.' +
              '<br>Fügen Sie die Makro Nummer in diesem Fenster im Feld "Makro Nummer" ein.' +
              '<br>Kopieren Sie den Text neben dem Feld "Formel Text".' +
              '<br>Öffnen Sie nun das Fenster "Dienstnehmer exportieren" in BMD.' +
              '<br>Rechts-klicken Sie auf die Spalten und wählen Sie "Formeln" -> "Neue Formel" aus.' +
              '<br>Vergeben Sie einen Namen (z.B. "Gültig-ab") und fügen Sie im Textfeld den in diesem Fenster angeführten Formeltext ein.' +
              '<br>Speichern Sie die Formel und schließen Sie das Fenster. Die Formel sollte nun in einer neuen Spalte zu sehen sein.' +
              '<br>',
          entries: entries,
          positiveButtonLabel: "BMD Makro \"Gültig ab\" herunterladen",
          selectCallback: function (result, event) {
            view.downloadValidFromMakro();
          },
          errorCheck: {
          },
        },
        header: 'BMD Makro \"Gültig ab\" herunterladen',
        expanded: true,
        centered: true,
      });
    },
    downloadValidFromMakro() {
      let view = this;

      let resourceName = "gueltig_ab_makro_import.xml";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    openWageFormulaPopup() {
      var view = this;

      let entries = [
        {
          label: "Formel Text",
          key: "formulaText",
          hidden: false,
          type: "text",
          copyButton: true,
          copyButtonTooltip: "Formeltext in die Zwischenablage kopieren",
          readonly: true,
          value: "FUNCTION[\"3949501\";\"1474975078000300700,-1,2,-1\"] §§T1S1E50L0/T6S20E49L1/",
        },
      ]

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        componentState: {
          description:
              'Diese Formel gibt beim Mitarbeiter Export den Lohn des Mitarbeiters laut Kollektivvertrag an.' +
              '<br><br>Um diese Formel in BMD nutzen zu können, öffnen Sie zunächst das Fenster "Dienstnehmer exportieren".' +
              '<br>Rechts-klicken Sie auf die Spalten und wählen Sie "Formeln" -> "Neue Formel" aus.' +
              '<br>Vergeben Sie einen Namen (z.B. "Lohn lt. KV") und fügen Sie im Textfeld den in diesem Fenster angeführten Formeltext ein.' +
              '<br>Klicken Sie rechts unten auf den Bleistift, um die Formel "Fixwert" zu berabeiten. Vergewissern Sie sich, dass die Lohnart "1: Grundbezug" ausgewählt ist.' +
              '<br>Speichern Sie die Formel und schließen Sie das Fenster. Die Formel sollte nun in einer neuen Spalte zu sehen sein.' +
              '<br>Falls die Formel noch keine Werte anzeigt, muss die Spalte in der Feldauswahl neu hinzugefügt werden.' +
              '<br>',
          entries: entries,
          positiveButtonLabel: "Schließen",
          selectCallback: function (result, event) {
          },
          errorCheck: {
          },
        },
        header: 'BMD Formel \"Lohn lt. KV\" kopieren',
        expanded: true,
        centered: true,
      });
    },

    // stapel import
    openBmdEditelFolderStructurePopup() {
      var view = this;

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        componentState: {
          description: 'Entpacken Sie das ZIP mit der Ordnerstruktur in Ihrem Editel Ordner auf Ihrer BMD Cloud oder Ihrem BMD Server ab.' +
              '<br>Benennen Sie anschließend den Ordner "Firmenname" auf den Namen Ihrer Firma um (z.B. "' + view.companyName + '").' +
              '<br><br>Diese Ordnersturktur wird NUR für den direkten Export von ML zu Ihrem BMD benötigt. Wenden Sie sich dazu an Ihren MeineLohnabrechnung Ansprechpartner.',
          entries: [],
          positiveButtonLabel: "Ordnerstruktur als ZIP herunterladen",
          selectCallback: function (result, event) {
            let resourceName = "editel_folder_structure.zip";
            view.loadResourceFile("bmd", resourceName)
              .then((resp) => {
                let content = view.$helpers.convertToUInt8(atob(resp.data.content));
                view.$helpers.forceFileDownload(content, resourceName);
              });
          },
          errorCheck: {
          },
        },
        header: 'BMD Import Ordner Struktur herunterladen',
        expanded: true,
        centered: true,
      });
    },
    openEmployeeImportStapelPopup() {
      var view = this;

      view.$helpers.OpenPopup({
        elRelative: null,
        remainAfterSelect: true,
        type: 'datafill',
        componentState: {
          description:
              'Öffnen Sie das Fenster "Makros" im Menü "Tools" in BMD.' +
              '<br>Wählen Sie nun in der Menüleiste des Fensters "Extras" -> "Importieren" aus. Wählen Sie nun die heruntergeladene XML Macro Datei aus.' +
              '<br><br>Öffnen Sie nun die Stapeldefinition.' +
              '<br>Importieren Sie die ZIP Datei via "Extras" -> "Ganzen Stapel importieren".' +
              '<br>Ändern Sie die Parameter wie folgt:' +
              '<br><br>Reihenfolge 1: Lohn-Basiseinstellungen setzen:' +
              '<br>Ändern Sie STP_HRS_DBSNR auf die Datenbestandsnummer, die Sie in Ihren Lohn-Basiseinstellungen finden. ' +
              'Die Firma, für die Sie diesen Stapel einrichten muss sich in diesem Datenbestand befinden.' +
              '<br><br>Reihenfolge 2: Firmenname:' +
              '<br>Ändern Sie die Pfade, damit sie auf Ihre Dateistruktur passt. Diese Pfade sollen auf die Ordnerstruktur zeigen, welche Sie hier in ML herunterladen können.' +
              '<br>Ändern Sie pSchnittstellenName auf den Namen der Schnittstelle, welche für Ihren Mitarbeiterimport aus ML verwendet werden soll.' +
              '<br>Ändern Sie pFirma auf die Firmennummer, für die Sie diesen Stapel benutzen möchten.' +
              '<br>Ändern Sie pMailEmpfaenger auf die Email Adresse, welche Benachrichtigungen zu Fehlern empfangen soll. ' +
              'Mit den Parametern pWarnungen und pError können Sie steuern welche Arten von Fehlern gesendert werden sollen (1 = senden, 0 = nicht senden).' +
              '<br>Ändern Sie pPathLogs auf den Pfad Ihrer Logdateien. Sie finden den Ort der Logdateien in dem Sie im Fenster "Dienstnehmer generieren mittels Importdatei"' +
              'auf "Funktionen" -> "Logdatei ansehen" klicken. Weiters klicken Sie im neuen Fenster auf "Funktionen" -> "LOG-Dateien öffnen".' +
              '<br><br>Kopieren Sie den Firmeneintrag für alle Firmen, die Sie importieren möchten.' +
              '<br>Kopieren Sie gegebenfalls die Lohn-Basiseinstellungen setzen, wenn sich Firmen in unterschiedlichen Datenbeständen befinden.' +
              '<br><br>Der Stapel kann nun getestet und automatisiert werden.' +
              '<br>Wenden Sie sich dazu an Ihren MeineLohnabrechnung Berater oder erstellen Sie ein Ticket.' +
              '<br>',
          entries: [],
          positiveButtonLabel: "MAKRO herunterladen",
          negativeButtonLabel: "STAPEL herunterladen",
          selectCallback: function (result, event) {
            view.downloadEmployeeImportMacro();
          },
          deleteCallback: function (result, event) {
            view.downloadEmployeeImportStapel();
          },
          errorCheck: {
          },
        },
        header: 'BMD Dienstnehmer Import Makro und Stapel herunterladen',
        expanded: true,
        centered: true,
      });
    },
    downloadEmployeeImportMacro() {
      let view = this;

      let resourceName = "BMD__FOR_FORMEL_749442349778069_dn_import.XML";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    downloadEmployeeImportStapel() {
      let view = this;

      let resourceName = "employee_import_stapel.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },
    openEmployeeExportStapelPopup() {
      var view = this;

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        remainAfterSelect: true,
        componentState: {
          description: 'Öffnen Sie die Stapeldefinition in BMD.' +
              '<br>Importieren Sie die ZIP Datei via "Extras" -> "Ganzen Stapel importieren".' +
              '<br>Ändern Sie die Parameter wie folgt:' +
              '<br><br>Reihenfolge 1: Lohn-Basiseinstellungen setzen:' +
              '<br>Ändern Sie STP_HRS_DBSNR auf die Datenbestandsnummer, die Sie in Ihren Lohn-Basiseinstellungen finden. ' +
              'Die Firma, für die Sie diesen Stapel einrichten muss sich in diesem Datenbestand befinden.' +
              '<br><br>Reihenfolge 2: Makro:' +
              '<br>Ändern Sie die Pfade, damit sie auf Ihre Dateistruktur passt. Diese Pfade sollen auf die Ordnerstruktur zeigen, welche Sie hier in ML herunterladen können.' +
              '<br>Ändern Sie pSchnittstellenName auf den Namen der Schnittstelle, welche für Ihren Mitarbeiterexport nach ML verwendet werden soll.' +
              '<br>Ändern Sie pFirma auf die Firmennummer, für die Sie diesen Stapel benutzen möchten.' +
              '<br>Ändern Sie pMailEmpfaenger auf die Email Adresse, welche Benachrichtigungen zu Fehlern empfangen soll. ' +
              'Mit den Parametern pWarnungen und pError können Sie steuern welche Arten von Fehlern gesendert werden sollen (1 = senden, 0 = nicht senden).' +
              '<br>Ändern Sie pPathLogs auf den Pfad Ihrer Logdateien. Sie finden den Ort der Logdateien in dem Sie im Fenster "Dienstnehmer generieren mittels Importdatei"' +
              'auf "Funktionen" -> "Logdatei ansehen" klicken. Weiters klicken Sie im neuen Fenster auf "Funktionen" -> "LOG-Dateien öffnen".' +
              '<br><br>Kopieren Sie den Firmeneintrag für alle Firmen, die Sie exportieren möchten.' +
              '<br>Kopieren Sie gegebenfalls die Lohn-Basiseinstellungen setzen, wenn sich Firmen in unterschiedlichen Datenbeständen befinden.' +
              '<br><br>Der Stapel kann nun getestet und automatisiert werden.' +
              '<br>Wenden Sie sich dazu an Ihren MeineLohnabrechnung Berater oder erstellen Sie ein Ticket.' +
              '<br>',
          entries: [],
          positiveButtonLabel: "BMD Dienstnehmer Export Stapel herunterladen",
          selectCallback: function (result, event) {
            view.downloadEmployeeExportStapel();
          },
          errorCheck: {
          },
        },
        header: 'BMD Dienstnehmer Export Stapel herunterladen',
        expanded: true,
        centered: true,
      });
    },
    downloadEmployeeExportStapel() {
      let view = this;

      let resourceName = "employee_export_stapel.zip";
      view.loadResourceFile("bmd", resourceName)
      .then((resp) => {
        let content = view.$helpers.convertToUInt8(atob(resp.data.content));
        view.$helpers.forceFileDownload(content, resourceName, true); //resp.data.name, true);
      });
    },

    // hidden for now
    openBmdLoginDataPopup() {
      var view = this;

      let entries = [
        {
          label: "Datenbank",
          key: "db",
          hidden: false,
          type: "text"
        },
        {
          label: "Benutzer",
          key: "user",
          hidden: false,
          type: "text"
        },
        {
          label: "Passwort",
          key: "pass1",
          hidden: false,
          type: "password"
        },
        {
          label: "Passwort wiederholen",
          key: "pass2",
          hidden: false,
          type: "password"
        },
      ];

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        componentState: {
          description: 'Geben Sie hier Ihre Zugangsdaten für BMD an.' +
              '<br>Aus Ihren Zugangsdaten wird ein Text Dokument erzeugt, welches für den Import von Daten aus ML in BMD via Kommandozeile benötigt wird.' +
              '<br>Legen Sie das Dokument an den entsprechenden Ort auf Ihrer BMD Cloud oder Ihrem BMD Server ab.' +
              '<br><br>Dieses Dokument wird NUR für den Import via Kommandozeile benötigt. Erzeugen Sie es nur nach Abspache mit Ihrem MeineLohnabrechnung Ansprechpartner.',
          entries: entries,
          positiveButtonLabel: "Dokument erzeugen und herunterladen",
          selectCallback: function (result, event) {
            let file = "Datenbank=" + result.db + "\r\n" +
                "Benutzer=" + result.user + "\r\n" +
                "Passwort=" + result.pass1;
            view.$helpers.forceFileDownload(file, "user_data.txt");
          },
          errorCheck: {
            pass1: (entries) => {
              if (entries.pass1 !== '' && entries.pass2 !== '' && entries.pass1 !== entries.pass2)
                return {
                  key: "pass1",
                  type: "error",
                  text: "Die Passwörter stimmen nicht überein.",
                };
              else return null;
            },
            pass2: (entries) => {
              if (entries.pass1 !== '' && entries.pass2 !== '' && entries.pass1 !== entries.pass2)
                return {
                  key: "pass1",
                  type: "error",
                  text: "Die Passwörter stimmen nicht überein.",
                };
              else return null;
            },
          },
        },
        header: 'BMD Zugangsdaten für Import via Kommandozeile generieren',
        expanded: true,
        centered: true,
      });
    },
    openBmdImportScriptPopup() {
      var view = this;

      let entries = [
        {
          label: "BMD Firmennummer",
          key: "externalCompanyId",
          hidden: false,
          type: "text",
          value: view.bmdId,
          disabled: true
        },
        {
          label: "BMD Installation (Ordner in dem BMDNTCS.exe liegt)",
          key: "bmdntcs",
          hidden: false,
          type: "text",
          placeholder: "C:\\Program Files(x86)\\BMDSoftware",
          forceLabel: true
        },
        {
          label: "BMD Import Funktion",
          key: "bmd_function",
          hidden: false,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: view.bmdImportScriptFunctions,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
        },
        {
          label: "Bezeichnung Schnittstelle",
          key: "bmd_interface",
          hidden: false,
          type: "text",
          placeholder: "ML Import",
          forceLabel: true
        },
        {
          label: "Editel Basisordner",
          key: "base_folder",
          hidden: false,
          type: "text",
          placeholder: "D:\\Daten\\Editel",
          forceLabel: true
        },
        {
          label: "Firma Ordnername",
          key: "company_name",
          hidden: false,
          type: "text",
          value: view.companyName,
          placeholder: view.companyName,
          forceLabel: true
        },
      ];

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        componentState: {
          description: 'Geben Sie hier die entsprechenden Daten für den gewünschten BMD Import an.' +
              '<br>Aus diesen Daten wird ein Import Skript erzeugt, welches für den Import von Daten aus ML in BMD via Kommandozeile benötigt wird.' +
              '<br>Legen Sie das Skript im Editel Firma Basisordner -> Import Art (siehe Name des erzeugten Skriptes) auf Ihrer BMD Cloud oder Ihrem BMD Server ab.' +
              '<br><br>Dieses Dokument wird NUR für den Import via Kommandozeile benötigt. Erzeugen Sie es nur nach Abspache mit Ihrem MeineLohnabrechnung Ansprechpartner.',
          entries: entries,
          positiveButtonLabel: "Import Script erzeugen und herunterladen",
          selectCallback: function (result, event) {
            view.createBmdImportScriptFile(result)
            .then((script) => {
              let content = view.$helpers.convertToUInt8(script);
              view.$helpers.forceFileDownload(content, "import_" + view.bmdImportScriptFunctionFolder[result.bmd_function] + ".bat");
            });
          },
          errorCheck: {
            bmdntcs: (entries) => {
              if (entries.bmdntcs === '' || entries.bmdntcs === undefined)
                return {
                  key: "bmdntcs",
                  type: "error",
                  text: "Der BMD Installationsordner muss befüllt sein.",
                };
              else return null;
            },
            bmd_function: (entries) => {
              if (entries.bmd_function === '' || entries.bmd_function === null)
                return {
                  key: "bmd_function",
                  type: "error",
                  text: "Bitte wählen Sie eine Import Funktion.",
                };
              else return null;
            },
            bmd_interface: (entries) => {
              if (entries.bmd_interface === '' || entries.bmd_interface === undefined)
                return {
                  key: "bmd_interface",
                  type: "error",
                  text: "Die Bezeichnung der Schnittstelle muss befüllt sein.",
                };
              else return null;
            },
            base_folder: (entries) => {
              if (entries.base_folder === '' || entries.base_folder === undefined)
                return {
                  key: "base_folder",
                  type: "error",
                  text: "Der Editel Basisordner muss befüllt sein.",
                };
              else return null;
            },
            company_name: (entries) => {
              if (entries.company_name === '' || entries.company_name === undefined)
                return {
                  key: "company_name",
                  type: "error",
                  text: "Der Firmen Ordner muss befüllt sein.",
                };
              else return null;
            },
          },
        },
        header: 'BMD Import Script für Import via Kommandozeile generieren',
        expanded: true,
        centered: true,
      });
    },
    createBmdImportScriptFile(result) {
      let view = this;

      return view.loadResourceFile("bmd", "import_script_template.txt")
      .then((resp) => {
        let content = atob(resp.data.content);
        content = content.replaceAll("[[externalCompanyId]]", result.externalCompanyId);
        content = content.replaceAll("[[bmdntcs]]", "\"" + result.bmdntcs + "\\BMDNTCS.exe\"");
        content = content.replaceAll("[[bmd_function]]", result.bmd_function);
        content = content.replaceAll("[[bmd_interface]]", result.bmd_interface);
        content = content.replaceAll("[[base_folder]]", "\"" + result.base_folder + "\"");
        content = content.replaceAll("[[company_name]]", "\"" + result.company_name + "\"");
        content = content.replaceAll("[[bmd_function_folder]]", this.bmdImportScriptFunctionFolder[result.bmd_function]);
        return content;
      });
    },

    loadResourceFile(resourceType, resourceName) {
      let url = "/api/sec/resource";
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, "/api/sec/resource");
      url = this.$helpers.appendParam("resourceType", resourceType, url, "/api/sec/resource");
      url = this.$helpers.appendParam("resourceName", resourceName, url, "/api/sec/resource");
      return this.axios({
        method: "get",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
      });
    },

    // CSV download
    downloadCSV() {
      let view = this;
      view.loadingDownload = true;

      let url = '/api/sec/employeesync/getexport';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);
      // url = this.$helpers.appendParam("remoteIds", [407,408], url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        let csv = response.data.data;
        //console.log(view.$helpers.b64DecodeUnicode(csv));
        view.$helpers.forceFileDownload(view.$helpers.b64DecodeUnicode(csv), "Export.csv");
        view.loadingDownload = false;
      }).catch((e) => {
        view.loadingDownload = false;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
      });
    },
    downloadCSVFromExportJob(csv) {
      let view = this;
      view.$helpers.forceFileDownload(csv, "Export.csv");
    },
    // CSV exports
    exportCSV() {
      let view = this;
      view.loadingExport = true;

      let url = '/api/sec/employeesync/export';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
      url = this.$helpers.appendParam("softwareId", view.companyData.softwareId, url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        view.loadingExport = false;
        view.$helpers.info(
          "Info",
          "Export in das LV Tool wurde erfolgreich angestoßen."
        );
      }).catch((e) => {
        view.loadingExport = false;
        view.$helpers.error(
          "Fehler",
          "Fehler beim Erzeugen des Exports."
        );
      });
    },
    loadExportJobs() {
      let view = this;

      let url = '/api/sec/employeesync/exports';
      let baseurl = url;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);

      this.axios({
        method: "get",
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then((response) => {
        view.exportJobs = response.data;

        Object.values(view.exportJobs).forEach((elem) => {
          elem.jobStarted = elem.jobStarted != null ? view.$helpers.convertUTCDateStringTZ(elem.jobStarted, 'DD.MM.YYYY HH:mm:ss') : null;
          elem.jobEnded = elem.jobEnded != null ? view.$helpers.convertUTCDateStringTZ(elem.jobEnded, 'DD.MM.YYYY HH:mm:ss') : null;
          view.setExportStatus(elem);
          view.loadAuthuser(elem.authuserId);
        });
      }).catch((e) => {

      });
    },
    setExportStatus(exportJob) {
      exportJob.status = "DONE"
      if (!exportJob.jobEnded) {
        exportJob.status = "FAILED";
      } else if (exportJob.employeeWarnings) {
        let jsonWarnings = JSON.parse(exportJob.employeeWarnings);

        let otherWarnings = 0;
        let ignorableWarnings = 0;
        let hardIgnorableWarnings = 0;

        Object.values(jsonWarnings).forEach(empWarnings => {
          Object.values(empWarnings.warnings).forEach(warning => {
            if (this.ignorableWarnings.includes(warning)) {
              ignorableWarnings++;
            } else if (this.hardIgnorableWarnings.includes(warning)) {
              hardIgnorableWarnings++;
            } else {
              otherWarnings++;
            }
          })
        });

        if (otherWarnings > 0) {
          exportJob.status = "WARNING";
        } else if (ignorableWarnings > 0) {
          exportJob.status = "IGNORE-WARNING";
        } else if (hardIgnorableWarnings > 0) {
          exportJob.status = "DONE"; // maybe add some other status. really ignore those for now
        }
      }
    },
    getInactiveEmployeeWarnings(exportJob) {
      let jsonWarnings = JSON.parse(exportJob.employeeWarnings);
      return Object.values(jsonWarnings).filter(empWarnings => Object.values(empWarnings.warnings).includes("Employee skipped: Inactive Employee"));
    },
    getExternalEmployeeWarnings(exportJob) {
      let jsonWarnings = JSON.parse(exportJob.employeeWarnings);
      return Object.values(jsonWarnings).filter(empWarnings => Object.values(empWarnings.warnings).includes("Employee skipped: External Employee"));
    },
    getHiddenEmployeeWarnings(exportJob) {
      let jsonWarnings = JSON.parse(exportJob.employeeWarnings);
      return Object.values(jsonWarnings).filter(empWarnings => Object.values(empWarnings.warnings).includes("Employee skipped: Hidden Employee"));
    },
    getOtherWarningsTable(exportJob) {
      let jsonWarnings = JSON.parse(exportJob.employeeWarnings);
      let table = [];

      Object.values(jsonWarnings).forEach(empWarnings => {
        Object.values(empWarnings.warnings).forEach(warning => {
          if (!this.ignorableWarnings.includes(warning) && !this.hardIgnorableWarnings.includes(warning)) {
            if (!table.find(w => w.name === warning)) {
              table.push({name: warning, emps: []});
            }
            if (!table.find(w => w.name === warning).emps.includes(empWarnings.employeeId)) {
              table.find(w => w.name === warning).emps.push(empWarnings.employeeId);
            }
          }
        })
      });
      return table;
    },

    jobCreated: function (e) {
      let view = this;
      setTimeout(function () {
        console.log("JOB CREATED EVENT GOT TRIGGERED");
        view.exportJobs.push(e.data.job);
      }, 50);
    },
    jobStarted: function (e) {
      let view = this;
      setTimeout(function () {
        console.log("JOB STARTED EVENT GOT TRIGGERED");
        let job = view.exportJobs.find(j => j.id === e.data.job.id);
        if (job) {
          job.jobStarted = view.$helpers.convertUTCDateStringTZ(e.data.job.jobStarted, 'DD.MM.YYYY HH:mm:ss');
          view.$forceUpdate();
        }
      }, 100);
    },
    jobEnded: function (e) {
      let view = this;
      setTimeout(function () {
        console.log("JOB ENDED EVENT GOT TRIGGERED");
        let job = view.exportJobs.find(j => j.id === e.data.job.id);
        if (job) {
          job.jobEnded = view.$helpers.convertUTCDateStringTZ(e.data.job.jobEnded, 'DD.MM.YYYY HH:mm:ss');
          job.exportCSV = e.data.job.exportCSV;
          job.employeeWarnings = e.data.job.employeeWarnings;
          view.setExportStatus(job);
          view.$forceUpdate();
        }
      }, 200);
    },
    jobFailed: function (e) {
      let view = this;
      setTimeout(function () {
        console.log("JOB FAILED EVENT GOT TRIGGERED");
        let job = view.exportJobs.find(j => j.id === e.data.job.id);
        if (job) {
          job.jobFailed = view.$helpers.convertUTCDateStringTZ(e.data.job.jobFailed, 'DD.MM.YYYY HH:mm:ss');
          view.$forceUpdate();
        }
      }, 200);
    },

    // fibu
    loadFinancialAccountingGroups: function(){
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      view.$helpers.GetCall('/api/financialaccountinggroups', params).then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            name: data[i].name,
            isDefault: data[i].isDefault,
          };
          tmp.push(item);
        }
        view.financialAccountingGroups = tmp;
      }).catch((e) => {
        view.loading = false;
        console.log(e);
      });
    },

    // helper
    getPayslipPasswordSettingByKey(key) {
      let label = "";
      this.payslipPasswordSettings.forEach(function (item, index) {
        if (item.value === key) {
          label = item.label;
        }
      });
      return label;
    },
    updateCompanySettings: function (response) {
      let view = this;
      view.hasAccessRights = true;

      if (response.data.length == 1) {
        view.companyData = response.data[0];

        view.internalId = view.companyData.id;
        view.companyName = view.companyData.name;
        view.companyDescription = view.companyData.description;
        view.bmdId = view.companyData.bmdId;
        view.dpwId = view.companyData.dpwId;

        view.inputBmdId = view.bmdId;
        view.inputDpwId = view.dpwId;
      }
    },
    openResetDropdown() {
      var view = this;
      // var target = $event.currentTarget;

      view.$helpers.OpenPopup({
        elRelative: null,
        type: 'datafill',
        componentState: {
          description: 'Wollen Sie wirklich alle Felder auf die vorgegebenen Standardwerte zurücksetzen?' +
            '<br>Damit werden sowohl der Aufbau und die Reihenfolge, als auch die individuellen Feldbezeichnungen zurückgesetzt.',
          entries: [],
          selectCallback: function () {
            view.employeeSyncFieldSettings = [];
            view.resetEmployeeSyncFields()
              .then((response) => {
                view.getEmployeeSyncFields();
              });
          }
        },
        header: 'Auf Standardwerte zurücksetzen',
        expanded: true,
        centered: true,
      });
    },
    printAssoArray(array) {
      let string = "";
      for (const index in array) {
        if (string !== "") {
          string += ", ";
        }
        string += index;
      }
      return string;
    },
    openAcDetailsModal() {
      this.$refs["modal-ac-details"].show();
    },
    closeAcDetailsModal() {
      this.$refs["modal-ac-details"].hide();
    },
    openExportJobStatusModal() {
      this.$refs["modal-exportjob-status"].show();
    },
    closeExportJobStatusModal() {
      this.selectedExportJob = null;
      this.$refs["modal-exportjob-status"].hide();
    },
    loadAuthuser(id) {
      let view = this;
      if (!view.authusers.hasOwnProperty(id)) {
        view.authusers[id] = {
          id: id,
          uuid: "",
          name: "Periodischer Start",
          mail: ""
        };
        let baseurl = '/api/sec/authuser';
        let url = baseurl;
        url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseurl);
        url = this.$helpers.appendParam("id", id, url, baseurl);

        return this.axios({
          method: 'get',
          headers: {'AUTHORIZATION': "Bearer " + this.$store.state.jwt},
          url: url,
        }).then(response => {
          view.authusers[id] = response.data.data;
        }).catch(function (error) {
          console.log(error);
        })
      } else {
        return Promise.resolve();
      }
    },
    openInNewTab(url) {
      window.open(url, '_blank');
    },
    getEmployeeName(empId) {
      let view = this;
      let emp = view.employeeArray.find(emp => emp.id === empId);
      if (emp) {
        return emp.firstName + " " + emp.lastName;
      } else {
        return "";
      }
    },

    save: function (key) {
      let view = this;
      view.saves = true;

      let promises = [];

      promises.push(view.saveCompanyData()
      .then((response) => {
        view.bmdId = view.inputBmdId;
        view.dpwId = view.inputDpwId;

        for (let i = 0; i < view.storesData.length; i++) {
          view.storesData[i].bmdId = view.storesData[i].inputBmdId;
          view.storesData[i].dpwId = view.storesData[i].inputDpwId;
          view.storesData[i].costCenter = view.storesData[i].inputCostCenter;
          view.storesData[i].storeNumber = view.storesData[i].inputStoreNumber;
          view.storesData[i].municipalitySerialNumber = view.storesData[i].inputMunicipalitySerialNumber;
          view.storesData[i].allocationGroup = view.storesData[i].inputAllocationGroup;
          view.storesData[i].bankAccountOriginator = view.storesData[i].inputBankAccountOriginator;
        }
      }));
      promises.push(view.saveCollectiveContracts()
      .then((response) => {

        if (response !== undefined && response.data !== undefined && response.data.data !== undefined) {
          Object.values(response.data.data).forEach(respEntry => {
            let result = view.collectiveContractMappingArray.find(res => {
              return res.mdId === respEntry.mdId;
            });
            result.id = (respEntry.externalIdEmployee || respEntry.externalIdWorker) ? respEntry.id : null; // remove id from deleted entries (external id == null)
            result.externalIdEmployee = respEntry.externalIdEmployee;
            result.externalIdWorker = respEntry.externalIdWorker;
            result.externalIdEmployee_input = respEntry.externalIdEmployee;
            result.externalIdWorker_input = respEntry.externalIdWorker;
          });

          // view.$helpers.info(
          //   "Speichern erfolgreich",
          //   "Kollektivverträge wurde erfolgreich gespeichert"
          // );
        }
      }));
      promises.push(view.saveContractWeekModelNrs()
      .then((response) => {
        this.similarContractWeekModelNrArray = []; // set to different value to force a recalculation of similarAllowedContracts computed value
        Object.values(view.allowedContractArray).forEach(acperstore => {
          Object.values(acperstore).forEach(ac => {
            ac.externalId = ac.externalId_input;
          })
        });

        // view.$helpers.info(
        //   "Speichern erfolgreich",
        //   "Wochenmodelle wurden erfolgreich gespeichert"
        // );
      }));
      promises.push(view.saveEmployeeSyncSettingsData()
      .then((response) => {
        view.employeeSyncSettingsData_input.id = response.data.id;
        view.employeeSyncSettingsData = JSON.parse(JSON.stringify(view.employeeSyncSettingsData_input));
        // view.$helpers.info(
        //   "Speichern erfolgreich",
        //   "Mitarbeiter Sync Einstellungen wurde erfolgreich gespeichert"
        // );
        VueEventBus.$emit("EmployeeSyncSettingsEvent:updated", view.employeeSyncSettingsData);
      }));
      promises.push(view.saveWageGroups()
      .then((response) => {
        view.wageGroup_input.id = response.data.id;
        view.wageGroup = view.wageGroup_input;
        // view.$helpers.info(
        //   "Speichern erfolgreich",
        //   "Tarifgruppen wurde erfolgreich gespeichert"
        // );
      }));
      promises.push(view.saveEmployeeSyncFieldSettings()
      .then((response) => {
        // view.$helpers.info(
        //   "Speichern erfolgreich",
        //   "SyncFelder wurden erfolgreich gespeichert"
        // );
      }));
      promises.push(view.savePeriodicExportTimes()
      .then(response => {
        view.periodicExportTimes = JSON.parse(JSON.stringify(response.data.data));
        view.periodicExportTimes_input = JSON.parse(JSON.stringify(response.data.data));
      }));

      Promise.all(promises)
      .then((resp) => {
        view.editieren = false;
        view.saves = false;
        view.$helpers.success(
            "Speichern erfolgreich",
            "Daten wurden erfolgreich gespeichert"
        );
      })
      .catch(function (error) {
        console.log(error);
        view.editieren = false;
        view.saves = false;
        // view.$helpers.error(
        //     "Speichern fehlgeschlagen",
        //     "Fehler beim Speichern der Daten"
        // );
      });
    },
    saveCompanyData() {
      let view = this;
      let payload = {
        companyId: this.$store.state.companyId,
        bmdId: this.inputBmdId,
        dpwId: this.inputDpwId,
      };
      payload["stores"] = [];
      for (let i = 0; i < view.storesData.length; i++) {
        payload["stores"].push({
          storeId: view.storesData[i].id,
          bmdId: view.storesData[i].inputBmdId,
          dpwId: view.storesData[i].inputDpwId,
          costCenter: view.storesData[i].inputCostCenter,
          storeNumber: view.storesData[i].inputStoreNumber,
          municipalitySerialNumber: view.storesData[i].inputMunicipalitySerialNumber,
          allocationGroup: view.storesData[i].inputAllocationGroup,
          bankAccountOriginator: view.storesData[i].inputBankAccountOriginator,
        });
      }

      let url = "/api/company";

      return this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        data: payload,
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.parentBox {

}

.xColumn2 {
  width: 48%;
  text-align: left;
  float: left;
  margin-left: 1%;
  margin-right: 1%;
  overflow-y: auto;
  padding-bottom: 20px;
}

.xColumn2 > .header {
  display: inline-block;
  width: 100%;
  color: #e51550;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  text-align: left;
  padding: 7px;
}

.entryBox {
  font-size: 13px;
  text-align: left;
  padding: 14px 7px;
}

.entryBox .entryHeader {
  display: inline-block;
  width: 50%;
  float: left;
  color: #555;
  font-weight: 700;
}

.entryBox .entryHeaderLight {
  display: inline-block;
  width: 50%;
  float: left;
  color: #aaa;
  font-weight: 200;
}

.entryBox .entryData {
  display: inline-block;
  width: 50%;
  float: right;
  color: #aaa;
  font-weight: 200;
}

.entryBox.triple .entryHeader.first {
  display: inline-block;
  width: 50%;
  float: left;
  color: #555;
  font-weight: 700;
}

.entryBox.triple .entryHeader {
  display: inline-block;
  width: 25%;
  float: left;
  color: #555;
  font-weight: 700;
}

.entryBox.triple .entryHeaderLight.first {
  display: inline-block;
  width: 50%;
  float: left;
  color: #aaa;
  font-weight: 200;
}

.entryBox.triple .entryHeaderLight {
  display: inline-block;
  width: 25%;
  float: left;
  color: #aaa;
  font-weight: 200;
}

.entryBox.triple .entryData.first {
  display: inline-block;
  width: 50%;
  float: left;
  color: #aaa;
  font-weight: 200;
}

.entryBox.triple .entryData {
  display: inline-block;
  width: 25%;
  float: left;
  color: #aaa;
  font-weight: 200;
}

.btn-ml {
  color: #fff;
  background-color: var(--ml);
  border-color: var(--ml);
}

.btn-ml:hover {
  color: #fff;
  background-color: #cc1347;
  border-color: #cc1347;
}

.table th {
  vertical-align: middle;
}

</style>
